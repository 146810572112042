import { Route, Switch } from 'react-router-dom';

import Form from './Form';
import Detail from './Detail';
import List from './List';
import AddressForm from './AddressForm';

const CompanyRoute = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/`} component={List} />
        <Route exact path={`${match.url}/create`} component={Form} />
        <Route exact path={`${match.url}/:id`} component={Detail} />
        <Route exact path={`${match.url}/:id/edit`} component={Form} />
        
        <Route exact path={`${match.url}/:id/address/create`} component={AddressForm} />
        <Route exact path={`${match.url}/:id/address/:id_address/edit`} component={AddressForm} />
      </Switch>
    </>
  );
}

export default CompanyRoute;