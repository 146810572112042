import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import SelectField from "src/misc/SelectField";
import SemanticField from "src/misc/SemanticField";
import * as yup from "yup";
import * as QUERIES from "./queries";

const validationSchema = yup.object().shape({
  id_box: yup.string().required(),
  id_outer_box: yup.string().required(),
  quantity: yup.number().integer().required().min(0),
  gauges: yup.array().of(yup.object().shape({
    id_line: yup.string().required(),
    quantity: yup.number().integer().required().min(0),
  })).required(),
  inventories: yup.array().of(yup.object().shape({
    id_line: yup.string().required(),
    quantity: yup.number().integer().required().min(0),
  })).required(),
});

const DialogCreateInnerBoxes = ({ id_invoice, gauge_lines, inventory_lines, open, setOpen, onComplete }) => {
  const formDataQuery = useQuery(QUERIES.q_boxes_list, { variables: { filter: { is_outer_box: false } } });
  const [m_inner_box_create] = useMutation(QUERIES.m_inner_box_create);

  const initialValues = validationSchema.cast({
    id_outer_box: open?.id,
    id_invoice,
    gauges: gauge_lines.map(line => ({ id_line: line.id, quantity: 0 })),
    inventories: inventory_lines.map(line => ({ id_line: line.id, quantity: 0 })),
  });
  
  const handleReset = () => { 
    setOpen(null); 
  }
  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    if (fields.id_box === '') return window.alert('Please select a box');
    if (fields.gauges.every(g => g.quantity <= 0) && fields.inventories.every(i => i.quantity <= 0)) return window.alert('Please enter quantity for at least one line'); 

    try {
      const { data } = await m_inner_box_create({ variables: { input: { ...fields, id_invoice } } })
      onComplete?.(data);
      setOpen(null);
    } catch ({ errors }) {
      window.alert(`Error in creating inner boxes - ${JSON.stringify(errors)}`);
    }
  }

  if (formDataQuery.loading) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;
  if (open != null && gauge_lines.length === 0 && inventory_lines.length === 0) {
    window.alert('No gauge or inventory lines have quantity remaining to be assigned.');
    setOpen(null);
    return null;
  };

  return (
    <Modal dimmer="blurring" open={open!=null} onClose={handleReset} size="mini">
      <Modal.Header>Create inner boxes for box #{open?.box_number}</Modal.Header>
      <Modal.Content>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset} validationSchema={validationSchema}>
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SelectField name="id_box" fluid isSearchable label="Box" options={formDataQuery.data.boxes.map(({ id, notes }) => ({ key: id, value: id, label: `${id} - ${notes}` }))} />
              <SemanticField name="quantity" component={Form.Input} label="Quantity" />
              {
                gauge_lines.map((line, idx) => (
                  <SemanticField name={`gauges.${idx}.quantity`} component={Form.Input} 
                    label={`Gauge ${line.id} (${line.quantity - line.production_status.qty_assigned_box} left)`} />
                ))
              }
              {
                inventory_lines.map((line, idx) => (
                  <SemanticField name={`inventories.${idx}.quantity`} component={Form.Input} 
                    label={`Inventory ${line.id} (${line.quantity - line.production_status.qty_assigned_box} left)`} />
                ))
              }
              <Button basic color='red' type="reset"><Icon name='remove' />Discard</Button>
              <Button color='green' type="submit"><Icon name='checkmark' />Add</Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

export default DialogCreateInnerBoxes;