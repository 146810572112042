import { Button, Container, Form, Icon } from 'semantic-ui-react';
import PageHeader from "src/misc/PageHeader";

const EinvoiceForm = ({ history }) => {

  const handleReset = () => { 
    history.go(-1);
  }
  
  const handleSubmit = () => {
    var totalfiles = document.getElementById('files').files.length;
    if (totalfiles > 0) {
      var formData = new FormData();
      formData.append("file", document.getElementById('files').files[0]);
      var xhttp = new XMLHttpRequest();
      // Set POST method and ajax file path
      xhttp.open("POST", '/pdf/einvoice', true);

      // call on request changes state
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4)  alert(this.responseText);
      };
      // Send request with data
      xhttp.send(formData);
    } else {
      alert("Please select a file");
    }
  }

  return (
    <>
      <PageHeader title="E-invoice upload" />
      <Container style={{ paddingTop: '48px' }}>
        <Form onSubmit={handleSubmit} onReset={handleReset}>

          <input style={{ marginBottom: '1rem' }} type="file" name="files" id="files" />

          <Button color="red" icon labelPosition="left" type="reset">
            <Icon name="cancel" />Discard
          </Button>
          <Button icon labelPosition="left" type="submit" >
            <Icon name="save" />Upload
          </Button>
        </Form>
      </Container>
    </>
  )
}

export default EinvoiceForm;