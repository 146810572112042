import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Form from './Form';
import Detail from './Detail';
import List from './List';
import DialTemplateArcForm from './arc/Form';
import DialTemplateCurvedTextForm from './curved_text/Form';
import DialTemplateImageForm from './image/Form';
import DialTemplateLineForm from './line/Form';
import DialTemplateScaleForm from './scale/Form';
import DialTemplateTextForm from './text/Form';


const ProductionDialTemplateRoute = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/`} component={List} />
        <Route exact path={`${match.url}/create`} component={Form} />
        <Route exact path={`${match.url}/:id`} component={Detail} />
        <Route exact path={`${match.url}/:id/edit`} component={Form} />
        
        <Route exact path={`${match.url}/:id/create_arc`} component={DialTemplateArcForm} />
        <Route exact path={`${match.url}/:id/arc/:id_arc/edit`} component={DialTemplateArcForm} />
        
        <Route exact path={`${match.url}/:id/create_curved_text`} component={DialTemplateCurvedTextForm} />
        <Route exact path={`${match.url}/:id/curved_text/:id_curved_text/edit`} component={DialTemplateCurvedTextForm} />
        
        <Route exact path={`${match.url}/:id/create_image`} component={DialTemplateImageForm} />
        <Route exact path={`${match.url}/:id/image/:id_image/edit`} component={DialTemplateImageForm} />
        
        <Route exact path={`${match.url}/:id/create_line`} component={DialTemplateLineForm} />
        <Route exact path={`${match.url}/:id/line/:id_line/edit`} component={DialTemplateLineForm} />
        
        <Route exact path={`${match.url}/:id/create_scale`} component={DialTemplateScaleForm} />
        <Route exact path={`${match.url}/:id/scale/:id_scale/edit`} component={DialTemplateScaleForm} />
        
        <Route exact path={`${match.url}/:id/create_text`} component={DialTemplateTextForm} />
        <Route exact path={`${match.url}/:id/text/:id_text/edit`} component={DialTemplateTextForm} />
      </Switch>
    </>
  );
}

export default ProductionDialTemplateRoute;