import gql from 'graphql-tag.macro';

export const m_create = gql`
  mutation sales_order_create ($input: SalesOrderCreateInput!) {
    sales_order_create (input: $input) {
      id
    }
  },
`;

export const m_delete = gql`
  mutation sales_order_delete ($id: ID!) {
    sales_order_delete (id: $id) {
      id
    }
  }
`;

export const m_update = gql`
  mutation sales_order_update ($id: ID!, $input: SalesOrderUpdateInput!) {
    sales_order_update (id: $id, input: $input) {
      id
    }
  },
`;

export const m_status_update = gql`
  mutation sales_order_status_update ($id: ID!, $status: String!, $id_user: ID!) {
    sales_order_status_update (id: $id, status: $status, id_user: $id_user) {
      id
    }
  }
`;

export const m_sales_order_line_delete = gql`
  mutation sales_order_line_delete ($id: ID!) {
    sales_order_line_delete (id: $id)
  }
`;

export const m_sales_order_line_duplicate = gql`
  mutation sales_order_line_duplicate ($id_line_from: ID!, $id_line_to: ID!, $quantity: Int!) {
    sales_order_line_duplicate (id_line_from: $id_line_from, id_line_to: $id_line_to, quantity: $quantity)
  }
`;

export const m_sales_order_line_split = gql`
  mutation sales_order_line_split ($id_line_from: ID!, $id_line_to: ID!, $quantity_move: Int!) {
    sales_order_line_split (id_line_from: $id_line_from, id_line_to: $id_line_to, quantity_move: $quantity_move)
  }
`;

export const m_sales_order_line_move = gql`
  mutation sales_order_line_move ($id_line_from: ID!, $id_line_to: ID!) {
    sales_order_line_move (id_line_from: $id_line_from, id_line_to: $id_line_to)
  }
`;

// Query used for prefilling sales_order update form with current values
export const q_update = gql`
  query update_sales_order ($id: ID!) {
    sales_order (id: $id) {
      id
      order_date
      id_currency
      id_customer
      id_address
      id_purchase_order
      purchase_order_date
      customer_notes
      production_notes
    }
  },
`;

export const q_form = gql`
  query sales_order_form {
    customers (limit: 10000, sort: { name: ASC }) {
      id
      name
      addresses {
        id
        street
        city
        district
        state
        country
        zipcode
      }
    }

    currencies {
      id
      name
    }
  }
`;

export const q_detail = gql`
  query sales_order ($id: ID!) {
    sales_order (id: $id) {
      id
      order_date
      id_purchase_order
      purchase_order_date
      status
      customer_notes
      production_notes
      currency {
        id
        name
        symbol
      }
      customer {
        id
        name
      }
      address {
        id
        street
        city
        state
        district
        country
        zipcode
      }
      quantity
      basic_amount
      inventory_sale_lines {
        id
        line
        inventory {
          model_code
        }
        invoice {
          id
          status
          invoice_date
        }
        quantity
        production_status {
          qty_assigned_box
          qty_packed
        }
        price
        amount
      }
      gauge_lines {
        id
        line
        invoice {
          id
          status
          invoice_date
        }
        model_code
        production_status {
          qty_assembled
          qty_angle_checked
          qty_calibrated
          qty_dial_crosschecked
          qty_switch_crosschecked
          qty_finished
          qty_checked
          qty_assigned_box
          qty_packed
        }
        quantity
        price
        amount
      }
    }
  },
`;

export const q_list = gql`
  query sales_orders ($limit: Int, $offset: Int, $filter: SalesOrderFilterInput, $sort: [SalesOrderSortInput!]) {
    list: sales_orders (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      id
      order_date
      id_purchase_order
      purchase_order_date
      currency {
        id
        symbol
      }
      customer {
        id
        name
      }
      status
      basic_amount
      quantity
      id_invoices
    }
  }
`;
