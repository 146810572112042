import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const BoxList = ({ match, history }) => {
  const [m_delete] = useMutation(QUERIES.m_delete);
  const user = useContext(UserContext);
  return (
    <>
      <PageHeader title="Boxes" 
        leftAction={user.isProduction && <Button onClick={() => history.push(`${match.url}/create`)}>New</Button>}
      />
      <CollectionList
        query={QUERIES.q_list}
        tableColumns={[
          { key: 'id', value: 'ID', width: '10%', sortable: true },
          { key: 'length', value: 'LENGTH (mm)', width: '5%' },
          { key: 'width', value: 'WIDTH (mm)', width: '5%' },
          { key: 'height', value: 'HEIGHT (mm)', width: '5%' },
          { key: 'weight', value: 'WEIGHT (kg)', width: '5%' },
          { key: 'is_outer_box', value: 'OUTER BOX', width: '15%', sortable: true },
          { key: 'notes', value: 'NOTES', width: 'auto' },
          { key: 'line_actions', sortable: false, value: '', width: '8%', hidden: !user.isProduction },
        ]}
        mapDataRowToTable={box => ({
          ...box,
          key: box.id,
          is_outer_box: box.is_outer_box ? '✔️' : '✘', 
          line_actions: (
            <div>
              <Button onClick={() => history.push(`${match.url}/${box.id}/edit`)} style={{ background: 'none' }} icon >
                <Icon name='pencil' />
              </Button>
              <Button style={{ background: 'none' }} icon 
                onClick={async () => {
                  if (window.confirm('Confirm deletion? Cannot delete if used anywhere.')) {
                    await m_delete({ variables: { id: box.id }});
                    history.go(0);
                  }
                }}
              >
                <Icon color="red" name="trash" />
              </Button>
            </div>
          ),
        })}
        defaultSortOrder={[{ is_outer_box: 'ASC' }, { id: 'ASC' }]}
        defaultFilters={{ id: '' }}
        filters={[
          { key: 'id', label: 'NOTES', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { };
          if (!isEmptyString(filterOptions.id))   queryFilters.id = filterOptions.id;
          return queryFilters;
        }}
      />
    </>
  )
};
export default BoxList;