import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { createUseStyles } from "react-jss";
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyItems: 'stretch',
    height: '40px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    backgroundColor: 'hsl(0,0%,100%)',
    border: '1px solid hsl(0,0%,80%)',
    // borderBottom: 'none',
    borderRadius: '10px 10px 0 0',
    '&>*': { whiteSpace: 'pre-wrap' },
  },
  editButton: {
    flex: '1 1 auto',
    border: 'none',
    backgroundColor: 'hsl(0,0%,15%)',
    color: 'hsl(0,0%,100%)',
    borderRadius: '0 0 0 10px',
    cursor: 'pointer',
    '&:hover': { backgroundColor: 'hsl(0,0%,0%)' },
  },
  deleteButton: {
    flex: '1 1 auto',
    border: 'none',
    backgroundColor: 'hsl(0,0%,85%)',
    borderRadius: '0 0 10px 0',
    cursor: 'pointer',
    '&:hover': { backgroundColor: 'hsl(0,0%,75%)' },
    color: 'hsl(0,100%,50%)',
  }
});


const AddressDetails = ({ address, refetchQuery }) => {
  const me = useContext(UserContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [m_delete] = useMutation(QUERIES.m_customer_address_delete);
  const classes = useStyles();

  const handleDelete = async (id) => {
    if (window.confirm('Confirm deletion?')) {
      await m_delete({ variables: { id }});
      refetchQuery();
    }
  }

  return (
    <div className={classes.flexContainer}>
      <div className={classes.detailsContainer}>
        <span>{address.street}</span>
        <span>{address.city} - {address.zipcode}</span>
        <span>{address.district}</span>
        <span>{address.state}</span>
        <span>{address.country}</span>
      </div>
      <div className={classes.buttonContainer}>
        { me.isSales && 
          <button className={classes.editButton} onClick={() => history.push(`${match.url}/address/${address.id}/edit`)}>
            <Icon name='edit' />
            Edit
          </button> 
        }
        { me.isSales && 
          <button className={classes.deleteButton} onClick={() => handleDelete(address.id)}>
            <Icon color="red" name="trash" />
            Delete
          </button>
        }
      </div>
    </div>
  );
}

export default AddressDetails;