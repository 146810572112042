import gql from 'graphql-tag.macro';

export const f_scalar_details = gql`
  fragment dial_template_text_scalar_details_fragment on DialTemplateText {
    id
    x
    y
    fontname
    fontsize
    fillcolor
    alignment
    text
    angle
    match_condition
  }
`;

export const m_delete = gql`
mutation dial_template_text_delete ($id: ID!){
  dial_template_text_delete (id: $id) {
    id
  }
}`;

export const m_create = gql`
mutation dial_template_text_create ($id_dial_template: ID!, $input: DialTemplateTextInput!){
  dial_template_text_create (id_dial_template: $id_dial_template, input: $input) {
    id
  },
}`;

export const m_update = gql`
mutation dial_template_text_update ($id: ID!, $input: DialTemplateTextInput!){
  dial_template_text_update (id: $id, input: $input) { 
    id
  },
}`;

export const q_update = gql`
query update_dial_template_text ($id: ID!) {
  dial_template_text (id: $id) {
    ...dial_template_text_scalar_details_fragment
  },
  ${f_scalar_details},
}`;