import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { interpolateColor } from 'src/misc/DataTable';
import DelayedLoader from 'src/misc/DelayedLoader';
import { useDateTime, usePageAutoscroll } from 'src/misc/hooks';
import logo from 'src/static/images/logo_inverted.png';
import { q_dashboard_dispatch } from './queries';
import styles from './styles';

const useStyles = createUseStyles(styles);

const getCellColoring = (value, maxValue) => {
  const percentValue = Math.round(100*value/maxValue)
  const backgroundColor = interpolateColor('#ffffff', '#000000', percentValue);
  const color = percentValue > 60 ? '#ffffff' : '#000000';
  return { color, backgroundColor, mixBlendMode: 'multiply', textAlign: 'center', };
}

const DispatchTableRow = (row) => {
  return (
    <tr className={row.address_billing.country === 'India' ? "domestic" : null} key={row.id}>
      <td>{dayjs(row.invoice_date).format('DD-MM-YYYY')}</td>
      <td>{row.id}</td>
      <td>{row.customer_billing.name}</td>
      <td>{row.id_sales_orders.join(', ')}</td>
      <td style={{textAlign: 'center'}}>{row.packing_status.quantity}</td>
      <td style={getCellColoring(row.packing_status.qty_assembled, row.packing_status.quantity)}>{row.packing_status.qty_assembled}</td>
      <td style={getCellColoring(row.packing_status.qty_calibrated, row.packing_status.quantity)}>{row.packing_status.qty_calibrated}</td>
      <td style={getCellColoring(row.packing_status.qty_dial_crosschecked, row.packing_status.quantity)}>{row.packing_status.qty_dial_crosschecked}</td>
      <td style={getCellColoring(row.packing_status.qty_finished, row.packing_status.quantity)}>{row.packing_status.qty_finished}</td>
      <td style={getCellColoring(row.packing_status.qty_checked, row.packing_status.quantity)}>{row.packing_status.qty_checked}</td>
      <td style={getCellColoring(row.packing_status.qty_packed, row.packing_status.quantity)}>{row.packing_status.qty_packed}</td>
    </tr>
  );
}

const DispatchStatusMonitor = () => {
  const classes = useStyles();
  const currentDate = useDateTime();
  usePageAutoscroll(30000);
  const { data, error } = useQuery(q_dashboard_dispatch, { 
    variables: { date_from: dayjs().subtract(1, 'week').format('YYYY-MM-DD'), date_to: dayjs().add(1, 'week').format('YYYY-MM-DD') }, 
    pollInterval: 10000,
  });

  if (data == null)  return <DelayedLoader />;
  if (error)    return error;

  const dispatch_groups = { overdue: [], today: [], this_week: [], rest: [] };
  let invoice;
  for (invoice of data.invoices) {
    const invoice_date = dayjs(invoice.invoice_date);
    const today = dayjs();
    if (invoice_date.isBefore(today, 'day'))      dispatch_groups.overdue.push(invoice);
    else if (invoice_date.isSame(today, 'day'))   dispatch_groups.today.push(invoice);
    else if (invoice_date.isSame(today, 'week'))  dispatch_groups.this_week.push(invoice);
    else                                          dispatch_groups.rest.push(invoice);
  }

  return (
    <div>
      <div className={classes.header}>
        <img alt="Hirlekar Precision logo" src={logo} />
        <div className={classes.title}>Dispatch Status</div>
        <div className={classes.date}>
          <div>{dayjs(currentDate).format('DD-MM-YYYY')}</div>
          <div>{dayjs(currentDate).format('hh:mm:ss A')}</div>
        </div>
      </div>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            {/* First blank row exists to set column widths for fixed layout table */}
            <tr>
              <td style={{ width: '7%' }}></td>
              <td style={{ width: '11%' }}></td>
              <td style={{ width: '25%' }}></td>
              <td style={{ width: '15%' }}></td>
              <td style={{ width: '6%' }}></td>
              <td style={{ width: '6%' }}></td>
              <td style={{ width: '6%' }}></td>
              <td style={{ width: '6%' }}></td>
              <td style={{ width: '6%' }}></td>
              <td style={{ width: '6%' }}></td>
              <td style={{ width: '6%' }}></td>
            </tr>

            { data.invoices.length === 0 && <tr className={classes.tableGroupHeader}><td colSpan={10}>No dispatches scheduled</td></tr> }

            { dispatch_groups.overdue.length > 0 && 
              <tr className={classes.tableGroupHeader}>
                <td colSpan={2}>Overdue</td>
              </tr>
            }
            { dispatch_groups.overdue.map(DispatchTableRow) }

            { dispatch_groups.today.length > 0 && 
              <tr className={classes.tableGroupHeader}>
                <td colSpan={2}>Today</td>
              </tr>
            }
            { dispatch_groups.today.map(DispatchTableRow) }
            
            { dispatch_groups.this_week.length > 0 && 
              <tr className={classes.tableGroupHeader}>
                <td colSpan={2}>This week</td>
              </tr>
            }
            { dispatch_groups.this_week.map(DispatchTableRow) }
            
            { dispatch_groups.rest.length > 0 && 
              <tr className={classes.tableGroupHeader}>
                <td colSpan={2}>Later</td>
              </tr>
            }
            { dispatch_groups.rest.map(DispatchTableRow) }
          </tbody>
        </table>
      </div>
      <div className={classes.footer}>
        <div style={{ flex: '7 7 0' }}></div>
        <div style={{ flex: '11 8 0' }}></div>
        <div style={{ flex: '25 28 0' }}></div>
        <div style={{ flex: '15 15 0' }}></div>
        <div style={{ flex: '6 6 0' }}>Qty</div>
        <div style={{ flex: '6 6 0' }}>Assembled</div>
        <div style={{ flex: '6 6 0' }}>Calibrated</div>
        <div style={{ flex: '6 6 0' }}>Crosscheck</div>
        <div style={{ flex: '6 6 0' }}>Finished</div>
        <div style={{ flex: '6 6 0' }}>Checked</div>
        <div style={{ flex: '6 6 0' }}>Packed</div>
      </div>
    </div>
  );
}

export default DispatchStatusMonitor;
