import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import { all, isNumber } from 'src/misc/validators.js';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validateNumArrayFromStr = str => (str === "") || str.split(',').map(isNumber).reduce(all, true);
const parseNumArrayFromStr = str => str === "" ? [] : str.split(',').map(i => Number(i));
const formatArrayToStr = arr => Array.isArray(arr) ? arr.join(', ') : arr;

const validationSchema = yup.object().shape({
  range_from: yup.number().required(),
  range_to: yup.number().required(),
  notes: yup.string().nullable(),
  major_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  minor_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  text_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  callout_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  calibration_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
  crosscheck_markings: yup.string().default('').test('numerical-array-check', '${path} array contains non-numerical member', validateNumArrayFromStr),  // eslint-disable-line
});

const ScaleForm = ({ history, match }) => {
  const isUpdateForm = match.params.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    fields.major_markings = parseNumArrayFromStr(fields.major_markings);
    fields.minor_markings = parseNumArrayFromStr(fields.minor_markings);
    fields.text_markings = parseNumArrayFromStr(fields.text_markings);
    fields.callout_markings = parseNumArrayFromStr(fields.callout_markings);
    fields.calibration_markings = parseNumArrayFromStr(fields.calibration_markings);
    fields.crosscheck_markings = parseNumArrayFromStr(fields.crosscheck_markings);
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id, input: fields }})
      : await createMutation({ variables: { input: fields } })
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.gauge_scale ?? validationSchema.default());
  initialValues.major_markings = formatArrayToStr(initialValues.major_markings);
  initialValues.minor_markings = formatArrayToStr(initialValues.minor_markings);
  initialValues.text_markings = formatArrayToStr(initialValues.text_markings);
  initialValues.callout_markings = formatArrayToStr(initialValues.callout_markings);
  initialValues.calibration_markings = formatArrayToStr(initialValues.calibration_markings);
  initialValues.crosscheck_markings = formatArrayToStr(initialValues.crosscheck_markings);

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Scale`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField fast name="range_from" width={8} component={Form.Input} label="Range from" type="number" />
              <SemanticField fast name="range_to" width={8} component={Form.Input} label="Range to" type="number" />
              <SemanticField fast name="notes" component={Form.TextArea} label="Notes" />
              <SemanticField fast name="major_markings" component={Form.TextArea} rows={1} label="Major markings" />
              <SemanticField fast name="minor_markings" component={Form.TextArea} rows={1} label="Minor markings" />
              <SemanticField fast name="text_markings" component={Form.TextArea} rows={1} label="Text markings" />
              <SemanticField fast name="callout_markings" component={Form.TextArea} rows={1} label="Callout markings" />
              <SemanticField fast name="calibration_markings" component={Form.TextArea} rows={1} label="Calibration markings" />
              <SemanticField fast name="crosscheck_markings" component={Form.TextArea} rows={1} label="Crosscheck markings" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default ScaleForm;