import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  tag_number: yup.string().nullable(),
});

const DialogGaugeUpdate = ({ open, setOpen, onComplete }) => {
  const [updateMutation] = useMutation(QUERIES.m_gauge_update);
  const updateQuery = useQuery(QUERIES.q_gauge_update, { variables: { id: open?.id }, skip: open == null });
  
  if (updateQuery.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (fields) => {
    fields.tag_number = fields.tag_number.trim();
    const { data } = await updateMutation({ variables: { id: open?.id , input: fields }})
    setOpen(null);
    onComplete && onComplete(data);
  }

  const handleReset = () => {
    setOpen(null);
  }
  const initialValues = stripTypename(updateQuery?.data?.gauge_line_gauge?.[0] ?? validationSchema.default());

  return (
    <Modal dimmer="blurring" open={open!=null} closeOnDimmerClick={false}>
      <Modal.Header>Update Gauge</Modal.Header>
      <Modal.Content>
        <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema}
          onSubmit={handleSubmit} onReset={handleReset}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="tag_number" component={Form.Input} label="Tag number" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  )
}

export default DialogGaugeUpdate;