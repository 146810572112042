import { useQuery } from '@apollo/client';
import clone from 'clone';
import qs from 'qs';
import { createUseStyles } from 'react-jss';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Input, Radio } from 'semantic-ui-react';
import DelayedLoader from './DelayedLoader';

const useStyles = createUseStyles({
  loader: {
    position: 'fixed',
    top: '50vh',
    left: '50vw',
    width: '2rem',
    height: '2rem',
  },
  container: {
    padding: '2rem',
  },
  filtersContainer: {
    border: '2px solid hsl(0,0%,95%)',
    borderRadius: '10px',
    marginBottom: '1rem',
    '& .header': {
      padding: '1rem',
      display: 'grid',
      gridTemplateColumns: '1fr 80px 1fr',
    },
    '& .pagination': {
      justifySelf: 'end',
      flex: '1 0 auto'
    },
  },
  filtersRow: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem 1rem',
    },
    '& > div > label': {
      fontWeight: 'bold',
      paddingBottom: '0.5rem',
    }
  },
  table: {
    tableLayout: 'fixed',
    position: 'relative',
    borderCollapse: 'collapse',
    width: '100%',
    '& tbody>tr': {
      '&:nth-child(2n+1)': {
        backgroundColor: 'hsl(0,0%,95%)',
      },
      '&:hover': {
        backgroundColor: 'hsl(0,0%,90%)',
      },
    },
    '& th,td': {
      padding: '1rem',
      wordBreak: 'break-all',
    },
    '& th': {
      cursor: 'pointer',
      top: '3.5rem',
      boxShadow: '0 2px 2px 0px rgba(0, 0, 0, 0.4)',
      backgroundColor: '#fff',
      position: 'sticky',
    }
  },
});

const objFromPath = (obj) => {
  const retObj = {};
  for (const key in obj) {
    const [prop, ...rest] = key.split('.');
    retObj[prop] = rest.length === 0
      ? obj[key]
      : objFromPath({ [rest.join('.')]: obj[key] });
  }
  return retObj;
}

const SortHint = ({ orderBy, col }) => {
  let key, sortNum;
  for (let i=0; i<orderBy.length; i++) {
    key = orderBy[i][col.path??col.key];
    sortNum = i;
    if (key != null) break;
  }
  if (key == null)  return null;
  else
    return `  (${key === 'ASC' ? '▲' : '▼' }${sortNum+1})`;
}

const CollectionList = (props) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  // Handle route params
  const params = qs.parse(location.search.slice(1));
  params.page = Number(params.page);
  params.pageSize = Number(params.pageSize);
  params.orderBy = params.orderBy ?? [];

  // Prepare graphql query
  const query_variables = {
    limit: params.pageSize,
    offset: params.pageSize * (params.page - 1),
    sort: params.orderBy.map(objFromPath),
    filter: props.mapFiltersToQuery(params),
  }
  const { data } = useQuery(props.query, { 
    variables: query_variables,
    skip: isNaN(query_variables.limit) || isNaN(query_variables.offset),
  });

  // Setup event handlers
  const handleClickHeader = (col) => () => {
    if (col.sortable !== true) return;
    let done = false;
    const orderBy = clone(params.orderBy);
    for (let i=0; i<orderBy.length; i++) {
      let key = orderBy[i];
      if (key[col.key] != null) {
        if (key[col.key] === 'ASC')  key[col.key] = 'DESC';
        else                        orderBy.splice(i, 1);
        done = true;
        break;
      }
    };
    if (!done)  orderBy.push({ [col.key]: 'ASC' });
    history.push(`${location.pathname}?${qs.stringify({ ...params, orderBy })}`);
  }

  // Render component
  if (location.search === "") 
    return <Redirect to={`${location.pathname}?${qs.stringify({ 
      page: 1, 
      pageSize: props.pageSize ?? 50,
      orderBy: props.defaultSortOrder,
      ...props.defaultFilters,
    })}`} />
  return (
    <div className={classes.container}>
      <div className={classes.filtersContainer}>
        <div className="header">
          <h3>Filters</h3>
          <Button onClick={() => history.push(`${location.pathname}?${qs.stringify({ ...params, ...props.defaultFilters })}`)}>
            Clear
          </Button>
          <div className="pagination">
            <span>Displaying </span>
            <select 
              value={params.pageSize}
              onChange={(e) => history.push(`${location.pathname}?${qs.stringify({ 
                ...params, 
                page: Math.round(query_variables.offset / Number(e.target.value)) + 1, 
                pageSize: e.target.value 
              })}`)}
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span> results per page </span>
            <Button compact basic size="small" icon="angle left"
              disabled={params.page<=1}
              onClick={() => history.push(`${location.pathname}?${qs.stringify({ ...params, page: params.page-1 })}`)}
            />
            <Button compact basic size="small" icon="angle right"
              disabled={data?.list.length === 0}
              onClick={() => history.push(`${location.pathname}?${qs.stringify({ ...params, page: params.page+1 })}`)}
            />
          </div>
        </div>
        <div className={classes.filtersRow}>
          { props.filters.map(filter => {
            if (filter.type === 'text')
              return <div key={filter.key}>
                <label>{filter.label}</label>
                <Input key={filter.key} type="text" icon="search" placeholder="Search..." value={params[filter.key]}
                  onChange={(e, { value }) => history.push(`${location.pathname}?${qs.stringify({ ...params, [filter.key]: value, page: 1 })}`)}
                />
              </div>
            if (filter.type === 'date')
              return <div key={filter.key}>
                <label>{filter.label}</label>
                <Input key={filter.key} type="date" value={params[filter.key]}
                  onChange={(e, { value }) => history.push(`${location.pathname}?${qs.stringify({ ...params, [filter.key]: value, page: 1 })}`)}
                />
              </div>
            if (filter.type === 'select')
              return <div key={filter.key}>
                <label>{filter.label}</label>
                <Dropdown key={filter.key} selection multiple={filter.multiple}  value={params[filter.key]}
                  onChange={(e, { value }) => history.push(`${location.pathname}?${qs.stringify({ ...params, [filter.key]: value, page: 1 })}`)}
                  options={filter.options.map(opt => ({ text: opt, value: opt }))} 
                />
              </div>
            if (filter.type === 'radio')
              return <div key={filter.key}>
                <label>{filter.label}</label>
                { filter.options.map(opt => 
                    <Radio key={opt} label={opt} name={filter.key} value={opt} checked={params[filter.key] === opt}
                      onClick={() => history.push(`${location.pathname}?${qs.stringify({ ...params, [filter.key]: opt, page: 1 })}`)}
                    />
                  )
                }
              </div>
            else return null;
          })}
        </div>
      </div>
      { data == null 
        ? <div className={classes.loader}><DelayedLoader /></div> 
        : <table className={classes.table}>
            <thead>
              <tr>
                { props.tableColumns.filter(({ hidden }) => !hidden).map(col => 
                    <th key={col.key} onClick={handleClickHeader(col)} style={{ width: col.width, textAlign: col.textAlign ?? 'left' }}>
                      {col.value}
                      <SortHint col={col} orderBy={params.orderBy} />
                    </th>
                  ) 
                }
              </tr>
            </thead>
            <tbody>
              { data?.list.map(props.mapDataRowToTable).map((row, i) => 
                  <tr key={row.key} 
                    style={{ cursor: props.handleClickRow != null ? 'pointer' : 'default' }}
                    onClick={() => { props.handleClickRow != null && props.handleClickRow(row.key, i) }}
                  >
                  { props.tableColumns.filter(({ hidden }) => !hidden).map(col => 
                      <td key={col.key} style={{ textAlign: col.textAlign ?? 'left', whiteSpace: col.whiteSpace ?? 'default' }}>{row[col.key]}</td>
                    )
                  }
                  </tr>
                )
              }
            </tbody>
          </table>
      }
    </div>
  );
}

export default CollectionList;