import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import DelayedLoader from 'src/misc/DelayedLoader.jsx';
import PropertiesTable from "src/misc/PropertiesTable.jsx";
import { UserContext } from "src/routes/index.jsx";
import * as QUERIES from './queries.js';

const useStyles = createUseStyles({
  flexContainer: {
    padding: '0 32px 32px 32px',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

const InventorySaleLineDetail = ({ match }) => {
  const user = useContext(UserContext);
  const classes = useStyles();
  const { data, error } = useQuery(QUERIES.q_detail_inventory_line, { variables: { id: match.params.id_line }});
  
  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`
  const line = data.inventory_sale_line;
  return (
    <div className={classes.flexContainer}>
      <Grid relaxed="very" celled="internally">
        <Grid.Row>
          <Grid.Column width={6}>
            <PropertiesTable
              tableProps={{ basic: 'very', celled: true, compact: 'very' }}
              keyWidth={6}
              items={[
                { key: 'Order #', value: <Link to={`/documents/sales_order/${line.id_sales_order}`}>{line.id_sales_order}</Link> },
                { key: 'Line #', value: line.line },
                line.id_invoice && { key: 'Invoice #', value: <Link to={`/documents/invoices/${line.id_invoice}`}>{line.id_invoice}</Link> },
                line.end_customer_po && { key: 'End customer PO #', value: line.end_customer_po },
              ]}
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <PropertiesTable
              tableProps={{ basic: 'very', celled: true, compact: 'very' }}
              items={[
                { key: 'Model code', value: line.inventory.model_code },
                line.customer_code && { key: 'Customer code', value: line.customer_code },
                (user.isSales || user.isAccounts || user.isOwner) && { key: 'Price', value: `${line.sales_order.currency.symbol} ${line.price}` },
                { key: 'Quantity', value: line.quantity },
                line.notes && { key: 'Notes', value: line.notes },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default InventorySaleLineDetail;