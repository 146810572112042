import React, { useState } from 'react';
import { Button, Form, Image, Message } from 'semantic-ui-react';
import logo from 'src/static/images/logo.png';
import qs from 'qs';

const formStyle = { 
  position: 'absolute', 
  width: '40%', 
  minWidth: '300px', 
  top: '50%', 
  left: '50%', 
  transform: 'translate(-50%, -50%)' 
}

const LoginPage = ({ history, location }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailed, setloginFailed] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitting(true);
    try {
      const res = await fetch('/api/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      if (res.status === 200) {
        const json = await res.json();
        json.expiresAt = Date.now() + json.maxAge;
        localStorage.setItem('hirlekarprecision.erp.webapp.user', JSON.stringify(json));
        const { redirect_uri } = qs.parse(location.search.slice(1));
        history.replace(redirect_uri?.replace('/webapp', '') ?? '/');
      } else {
        setPassword('');
        setloginFailed(true);
        setFormSubmitting(false);
      } 
    } catch (error) {
      console.error(`Error: ${error}`);  
      setFormSubmitting(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} style={formStyle} error={loginFailed}>
      <Image style={{ marginBottom: '100px' }} centered size="large" src={logo} />
      <Form.Input label="Username" value={username} onChange={(e, { value }) => setUsername(value)} autoFocus />
      <Form.Input label="Password" type="password" value={password} onChange={(e, { value }) => setPassword(value)} />
      <Message
        error
        header='Login Failed'
        content='Incorrect username or password'
      />
      <Button disabled={formSubmitting} type="submit" content="Login" />
    </Form>
  );
}

export default LoginPage;