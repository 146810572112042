import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { useContext, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Icon, Menu, Portal } from 'semantic-ui-react';
import DataTable from 'src/misc/DataTable';
import DelayedLoader from 'src/misc/DelayedLoader';
import { fragmentNewlines } from "src/misc/helpers";
import PropertiesTable from "src/misc/PropertiesTable";
import TitledSegment from 'src/misc/TitledSegment';
import { UserContext } from 'src/routes/index.jsx';
import DuplicateLineDialog from './DialogDuplicateLine';
import MoveLineDialog from './DialogMoveLine';
import SplitLineDialog from './DialogSplitLine';
import * as QUERIES from './queries.js';
import PageHeader from 'src/misc/PageHeader';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  statusSubheader: {
    fontSize: '1.2rem',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 868px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  mainContainer: {
    '&>*': {
      padding: '3rem 3rem',
      '&>h2': {
        color: 'hsl(0, 0%, 40%)',
        fontWeight: 'bold',
        marginBottom: '1em',
      },
    },
    '&>:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&>:nth-child(2n+3)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});


const SalesOrderDetails = ({ history, match }) => {
  const user = useContext(UserContext);
  const classes = useStyles();
  const [duplicateDialogLineId, setDuplicateDialogLineId] = useState(null);
  const [splitDialogLineId, setSplitDialogLineId] = useState(null);
  const [moveDialogLineId, setMoveDialogLineId] = useState(null);
  const [contextMenuState, setContextMenuState] = useState(null);
  const { data, error, refetch } = useQuery(QUERIES.q_detail, { variables: { id: match.params.id }});
  const [m_update] = useMutation(QUERIES.m_update, { variables: { id: match.params.id }});
  const [m_delete] = useMutation(QUERIES.m_delete, { variables: { id: match.params.id }});
  const [m_line_delete] = useMutation(QUERIES.m_sales_order_line_delete);
  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`
  const sales_order = data.sales_order;

  let isArchived = false;
  if (sales_order.status === 'Active') {
    for (let line of [...sales_order.gauge_lines, ...sales_order.inventory_sale_lines]) {
      if (line.invoice && !['Sales', 'Production'].includes(line.invoice.status)) {
        isArchived = true;
        break;
      }
    }
  } else isArchived = true;

  const handleOpenContexMenu = line_type => id_line => event => {
    event.preventDefault();
    setContextMenuState({
      id_line,
      line_type,
      mouseX: event.pageX - 2,
      mouseY: event.pageY - 4,
    });
  };

  const handleClickMenuItem = (action) => async () => {
    if (action === 'edit') {
      if (contextMenuState.line_type === 'GaugeLine')  
        history.push(`${match.url}/gauge_line/${contextMenuState.id_line}/edit`);
      else history.push(`${match.url}/inventory_line/${contextMenuState.id_line}/edit`);
    }
    else if (action === 'delete') {
      if (window.confirm('Confirm deletion?')) {
        await m_line_delete({ variables: { id: contextMenuState.id_line }});
        refetch();
      }
    }
    else if (action === 'duplicate') {
      setDuplicateDialogLineId(contextMenuState.id_line);
    }
    else if (action === 'split') {
      setSplitDialogLineId(contextMenuState.id_line);
    }
    else if (action === 'move') {
      setMoveDialogLineId(contextMenuState.id_line);
    }
    setContextMenuState(null); 
  }
  
  const LineContextMenu = () => {
    if (contextMenuState == null) 
      return null;

    let line = sales_order.gauge_lines.find(line => line.id === contextMenuState.id_line);
    if (line == null)
      line = sales_order.inventory_sale_lines.find(line => line.id === contextMenuState.id_line);

    const isLineArchived = line.invoice && !['Sales', 'Production'].includes(line.invoice.status);
    return (
      <Portal open={true} onClose={() => setContextMenuState(null)} >
        <Menu vertical style={{ position: 'absolute', top: contextMenuState.mouseY, left: contextMenuState.mouseX, zIndex: 1000 }}>
          { user.isSales && !isLineArchived && <Menu.Item onClick={handleClickMenuItem('edit')}>Edit</Menu.Item>}
          { user.isSales && !isLineArchived && <Menu.Item onClick={handleClickMenuItem('delete')}>Delete</Menu.Item>}
          { user.isSales && <Menu.Item onClick={handleClickMenuItem('duplicate')}>Duplicate</Menu.Item>}
          { user.isSales && !isLineArchived && <Menu.Item onClick={handleClickMenuItem('split')}>Split</Menu.Item>}
          { user.isSales && !isLineArchived && <Menu.Item onClick={handleClickMenuItem('move')}>Move</Menu.Item>}
        </Menu>
      </Portal>
    );
  }
  
  return (
    <>
      <DuplicateLineDialog idLineFrom={duplicateDialogLineId} setIdLineFrom={setDuplicateDialogLineId} onComplete={refetch} />
      <SplitLineDialog idLineFrom={splitDialogLineId} setIdLineFrom={setSplitDialogLineId} onComplete={refetch} />
      <MoveLineDialog idLineFrom={moveDialogLineId} setIdLineFrom={setMoveDialogLineId} onComplete={refetch} />
      <LineContextMenu />
      <PageHeader title={`Sales Order: ${sales_order.id}`} />
      <div className={classes.mainContainer}>
        <div className={classes.flexContainer}>
          <div className={classes.details}>
            <Grid celled='internally'>
              <Grid.Row>
                <Grid.Column width={8}>
                  <PropertiesTable
                    tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                    items={[
                      { key: 'Status', value: sales_order.status },
                      { key: 'Order date', value: dayjs(sales_order.order_date).format('DD MMM YY') },
                      { key: 'PO #', value: sales_order.id_purchase_order },
                      { key: 'PO Date', value: dayjs(sales_order.purchase_order_date).format('DD MMM YY') },
                      { key: 'Currency', value: `${sales_order.currency.name} (${sales_order.currency.symbol})` },
                    ]}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as={Link} to={`/masters/customer/${sales_order.customer.id}`}>{sales_order.customer.name}</Header>
                  <PropertiesTable
                    tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                    items={[
                      { key: 'Street', value: sales_order.address.street },
                      { key: 'City', value: sales_order.address.city },
                      { key: 'Zipcode', value: sales_order.address.zipcode },
                      { key: 'District', value: sales_order.address.district },
                      { key: 'State', value: sales_order.address.state },
                      { key: 'Country', value: sales_order.address.country },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              { sales_order.customer_notes && <Grid.Row>
                <Grid.Column>
                  <Header as='h4'>Customer Notes</Header>
                  <p>{fragmentNewlines(sales_order.customer_notes)}</p>
                </Grid.Column>
              </Grid.Row> }
              { sales_order.production_notes && <Grid.Row>
                <Grid.Column>
                  <Header as='h4'>Production Notes</Header>
                  <p>{fragmentNewlines(sales_order.production_notes)}</p>
                </Grid.Column>
              </Grid.Row> }
            </Grid>
          </div>
          <div className={classes.actions}>
            <TitledSegment title='Actions'>
              { user.isSales && sales_order.status === 'Active' && 
                <button onClick={() => {
                  if (window.confirm('Confirm shortclose order?'))
                    m_update({ variables: { input: { status: 'Shortclosed', id_shortclosed_by: user.id, shortclosed_at: 'now()' }}});
                    refetch();
                }}>
                  Shortclose
                </button> 
              }
              { user.isSales && sales_order.status === 'Active' && 
                <button onClick={() => {
                  if (window.confirm('Confirm order fulfilled?'))
                    m_update({ variables: { input: { status: 'Fulfilled', id_fulfilled_by: user.id, fulfilled_at: 'now()' }}});
                    refetch();
                }}>
                  Fulfilled
                </button> 
              }
              { user.isSales && 
                <button 
                  disabled={isArchived}
                  title={isArchived ? 'Cannot edit once some lines have shipped' : null} 
                  onClick={() => history.push(`./${sales_order.id}/edit`)}
                >
                  <Icon name='edit' />
                  Edit
                </button>
              }
              { user.isSales && 
                <button disabled={isArchived || sales_order.gauge_lines.length !== 0 || sales_order.inventory_sale_lines.length !== 0}
                  title={isArchived 
                    ? 'Cannot delete once some lines have shipped'
                    : sales_order.gauge_lines.length !== 0 || sales_order.inventory_sale_lines.length !== 0 
                      ? 'Cannot delete sales order until all lines are deleted' 
                      : null
                  }
                  onClick={() => window.confirm('Confirm deletion?') && m_delete() && history.push('./')}
                >
                  <Icon color="red" name="trash" />
                  Delete
                </button> 
              }
            </TitledSegment>
            <TitledSegment title='Documents'>
              {user.isSales && <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/order-acceptance`)}><Icon name="file pdf" />Sales Order</button>}
              <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/work-order`)}><Icon name="file pdf" />Work Order</button>
              <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/calibration-certificates`)}><Icon name="file pdf" />Calibration Certificates</button>
              <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/test-certificates`)}><Icon name="file pdf" />Test Certificates</button>
              <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/gauge-labels`)}><Icon name="file pdf" />Gauge Labels</button>
              <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/inner-box-labels`)}><Icon name="file pdf" />Inner Box Labels</button>
              <button onClick={() => window.open(`/pdf/sales-orders/${sales_order.id}/working-labels`)}><Icon name="file pdf" />Working Labels</button>
            </TitledSegment>
          </div>
        </div>
        <div>
          <h2>Gauges</h2>
          {sales_order.gauge_lines.length > 0 && 
            <DataTable
              tableProps={{ fixed: true, celled: true, selectable: true, basic: 'very' }}
              rowProps={{ style: { cursor: 'pointer' }}}
              endReached={true}
              onClick={(id) => history.push(`${match.url}/gauge_line/${id}`)}
              handleContextMenu={handleOpenContexMenu('GaugeLine')}
              columns={[
                { key: 'line', value: 'Line', width: 1 },
                { key: 'model_code', value: 'Model code', width: 4 },
                { key: 'id_invoice', value: 'Invoice', width: 2 },
                { key: 'qty_assembled', value: 'Assembly', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_angle_checked', value: 'Angle Check', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_calibrated', value: 'Calibration', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_dial_crosschecked', value: 'Dial Cross', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_switch_crosschecked', value: 'Switch Cross', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_finished', value: 'Finishing', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_checked', value: 'QC', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_packed', value: 'Packing', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'price', value: `Price (${sales_order.currency.symbol})`, width: 1, textAlign: 'right', 
                  format: (val) => numeral(val).format('0,0.00'), hidden: !user.isSales && !user.isAccounts && !user.isOwner  },
                { key: 'quantity', value: 'Quantity', textAlign: 'right', footer: 'total', width: 1 },
                { key: 'amount', value: `Amount (${sales_order.currency.symbol})`, width: 1, textAlign: 'right', footer: 'total', 
                  format: (val) => numeral(val).format('0,0.00'), hidden: !user.isSales && !user.isAccounts && !user.isOwner  },
              ]}
              rows={sales_order.gauge_lines.map((line) => ({
                key: line.id,
                id_invoice: line.invoice ? line.invoice.id : null,
                ...line.production_status,
                ...line,
              }))}
            />}
          { user.isSales && sales_order.status === 'Active' && 
            <Button style={{ marginTop: '32px' }} secondary onClick={() => history.push(`${match.url}/create_gauge_line`)}>
              <Icon name='plus' />
              New gauge line
            </Button>
          }
        </div>
        <div>
          <h2>Inventories</h2>
          {sales_order.inventory_sale_lines.length > 0 && 
            <DataTable
              tableProps={{ fixed: true, celled: true, selectable: true, basic: 'very' }}
              rowProps={{ style: { cursor: 'pointer' }}}
              endReached={true}
              onClick={(id) => history.push(`${match.url}/inventory_line/${id}`)}
              handleContextMenu={handleOpenContexMenu('InventoryLine')}
              columns={[
                { key: 'line', value: 'Line', width: 1 },
                { key: 'model_code', value: 'Model code', width: 4 },
                { key: 'id_invoice', value: 'Invoice', width: 2 },
                { key: 'qty_packed', value: 'Packed', textAlign: 'right', footer: 'total', type: 'percent', maxValue: 'quantity' },
                { key: 'price', value: `Price (${sales_order.currency.symbol})`, width: 1, textAlign: 'right', 
                  format: (val) => numeral(val).format('0,0.00'), hidden: !user.isSales && !user.isAccounts && !user.isOwner  },
                { key: 'quantity', value: 'Quantity', textAlign: 'right', footer: 'total', width: 1 },
                { key: 'amount', value: `Amount (${sales_order.currency.symbol})`, width: 1, textAlign: 'right', footer: 'total', 
                  format: (val) => numeral(val).format('0,0.00'), hidden: !user.isSales && !user.isAccounts && !user.isOwner },
              ]}
              rows={sales_order.inventory_sale_lines.map((line) => ({
                key: line.id,
                id_invoice: line.invoice ? line.invoice.id : null,
                model_code: line.inventory.model_code,
                ...line.production_status,
                ...line,
              }))}
            />}
          { user.isSales && sales_order.status === 'Active' && 
            <Button style={{ marginTop: '32px' }} secondary onClick={() => history.push(`${match.url}/create_inventory_line`)}>
              <Icon name='plus' />
              New inventory sale line
            </Button>
          }
        </div>
      </div>
    </>
  );   
}

export default SalesOrderDetails;