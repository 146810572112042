import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { FONT_OPTIONS, TEXT_ALIGNMENT_OPTIONS } from 'src/misc/dropdownOptions.js';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import { isEmptyString } from 'src/misc/validators';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  cx: yup.number().required(),
  cy: yup.number().required(),
  r: yup.number().required(),
  angle: yup.number().required(),
  fontsize: yup.number().required(),
  fontname: yup.string().required(),
  fillcolor: yup.string().required().default('#000000'),
  text: yup.string().required(),
  is_flipped: yup.bool().default(true),
  match_condition: yup.string().nullable(),
  alignment: yup.string().required(),
});

const DialTemplateCurvedTextForm = ({ history, match }) => {
  const isUpdateForm = match.params.id_curved_text != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id_curved_text }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    if(isEmptyString(fields.match_condition)) fields.match_condition = null;
    fields.fillcolor = fields.fillcolor?.trim();
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id_curved_text, input: fields }})
      : await createMutation({ variables: { input: fields, id_dial_template: match.params.id } });
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.dial_template_curved_text ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Curved Text`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="text" component={Form.Input} label="Text" type="text" />
              <SemanticField name="cx" component={Form.Input} label="Arc center X (mm)" type="number" />
              <SemanticField name="cy" component={Form.Input} label="Arc center Y (mm)" type="number" />
              <SemanticField name="r" component={Form.Input} label="Arc radius (mm)" type="number" />
              <SemanticField name="angle" component={Form.Input} label="Arc center (deg)" type="number" />
              <SemanticField name="fontsize" component={Form.Input} label="Font size (mm)" type="number" />
              <SemanticField name="fontname" component={Form.Dropdown} label="Font name" selection options={FONT_OPTIONS} />
              <SemanticField name="alignment" component={Form.Dropdown} label="Text alignment" selection options={TEXT_ALIGNMENT_OPTIONS} />
              <SemanticField name="fillcolor" component={Form.Input} label="Color" />
              <SemanticField name="is_flipped" component={Form.Checkbox} label="Draw on top side of arc" />
              <SemanticField name="match_condition" component={Form.Input} label="Draw on condition" type="text" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default DialTemplateCurvedTextForm;