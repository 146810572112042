import gql from 'graphql-tag.macro';

const f_scale_scalar_details = gql`
  fragment GaugeScaleDetailsFragment on GaugeScale {
    id
    major_markings
    minor_markings
    callout_markings
    text_markings
    calibration_markings
    crosscheck_markings
    range_from
    range_to
    notes
  }
`;

export const m_create = gql`
  mutation gauge_scale_create ($input: GaugeScaleCreateInput!) {
    gauge_scale_create (input: $input) {
      id
    },
  }
`;

export const m_delete = gql`
  mutation gauge_scale_delete ($id: ID!) {
    gauge_scale_delete (id: $id) {
      id
    }
  }
`;

export const m_update = gql`
  mutation gauge_scale_update ($id: ID!, $input: GaugeScaleUpdateInput!) {
    gauge_scale_update (id: $id, input: $input) {
      id
    },
  }
`;

export const q_update = gql`
  query update_scale ($id: ID!) {
    gauge_scale (id: $id) {
      ...GaugeScaleDetailsFragment
    },
  }
  ${f_scale_scalar_details},
`;

export const q_list = gql`
  query gauge_scales ($limit: Int, $offset: Int, $filter: GaugeScaleFilterInput, $sort: [GaugeScaleSortInput!]) {
    list: gauge_scales (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      ...GaugeScaleDetailsFragment
    },
  }
  ${f_scale_scalar_details}
`;