import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import { isEmptyString } from 'src/misc/validators';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  cx: yup.number().required(),
  cy: yup.number().required(),
  d: yup.number().required(),
  start_angle: yup.number().required(),
  arc_angle: yup.number().required(),
  strokewidth: yup.number().required(),
  strokecolor: yup.string().required().default('#000000'),
  notes: yup.string().nullable(),
});

const DialTemplateArcForm = ({ history, match }) => {
  const isUpdateForm = match.params.id_arc != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id_arc }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    if(isEmptyString(fields.notes)) fields.notes = null;
    fields.strokecolor = fields.strokecolor?.trim();
    isUpdateForm 
      ? await updateMutation({ variables: { id: match.params.id_arc, input: fields }})
      : await createMutation({ variables: { input: fields, id_dial_template: match.params.id } });
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.dial_template_arc ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Arc`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="cx" component={Form.Input} label="Arc center X (mm)" type="number" />
              <SemanticField name="cy" component={Form.Input} label="Arc center Y (mm)" type="number" />
              <SemanticField name="d" component={Form.Input} label="Arc diameter (mm)" type="number" />
              <SemanticField name="start_angle" component={Form.Input} label="Arc start (deg)" type="number" />
              <SemanticField name="arc_angle" component={Form.Input} label="Arc angle (deg)" type="number" />
              <SemanticField name="strokewidth" component={Form.Input} label="Stroke width (mm)" type="number" />
              <SemanticField name="strokecolor" component={Form.Input} label="Stroke color" />
              <SemanticField name="notes" component={Form.TextArea} label="Notes"  />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default DialTemplateArcForm;