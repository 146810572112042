import { useMutation, useQuery } from "@apollo/client";
import { Formik } from "formik";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import SelectField from "src/misc/SelectField";
import SemanticField from "src/misc/SemanticField";
import * as yup from "yup";
import * as QUERIES from "./queries";

const validationSchema = yup.object().shape({
  id_box: yup.string().required(),
  box_number: yup.number().integer().required().default(1).min(1),
  tare_weight: yup.number().required().default(0),
});

const DialogAddOuterBox = ({ id_invoice, open, setOpen, onComplete }) => {
  const formDataQuery = useQuery(QUERIES.q_boxes_list, { variables: { filter: { is_outer_box: true } } });
  const [m_outer_box_create] = useMutation(QUERIES.m_outer_box_create);
  const [m_outer_box_update] = useMutation(QUERIES.m_outer_box_update);

  const isUpdateForm = open?.id != null;
  const initialValues = isUpdateForm ? { ...open } : { ...validationSchema.default(), box_number: open?.box_number };
  
  const handleReset = () => { 
    setOpen(null); 
  }
  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    if (fields.id_box === '') return window.alert('Please select a box');
    try {
      const { data } = isUpdateForm
        ? await m_outer_box_update({ variables: { id: open.id, fields: { ...fields } } })
        : await m_outer_box_create({ variables: { input: { id_invoice, ...fields } } });
      onComplete?.(data);
      setOpen(null);
    } catch ({ errors }) {
      window.alert(`Error in creating box - ${JSON.stringify(errors)}`);
    }
  }

  if (formDataQuery.loading) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;

  return (
    <Modal dimmer="blurring" open={open!=null} onClose={handleReset} size="mini">
      <Modal.Header>Add outer box to invoice {id_invoice}</Modal.Header>
      <Modal.Content>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset} validationSchema={validationSchema}>
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SelectField name="id_box" fluid isSearchable label="Box" options={formDataQuery.data.boxes.map(({ id, notes }) => ({ key: id, value: id, label: `${id} - ${notes}` }))} />
              <SemanticField name="box_number" component={Form.Input} label="Box number" />
              <SemanticField name="tare_weight" component={Form.Input} label="Tare weight (kgs)" />
              <Button basic color='red' type="reset"><Icon name='remove' />Discard</Button>
              <Button color='green' type="submit"><Icon name='checkmark' />Add</Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

export default DialogAddOuterBox;