import gql from 'graphql-tag.macro';

export const m_create = gql`
  mutation inventory_create ($input: InventoryCreateInput!) {
    inventory_create (input: $input) {
      id
    }
  },
`;

export const m_delete = gql`
  mutation inventory_delete ($id: ID!) {
    inventory_delete (id: $id) {
      id
    }
  }
`;

export const m_update = gql`
  mutation inventory_update ($id: ID!, $input: InventoryUpdateInput!) {
    inventory_update (id: $id, input: $input) {
      id
    }
  },
`;

export const q_update = gql`
  query inventory_update ($id: ID!) {
    inventory (id: $id) {
      model_code
      description
      product_category
      hsn_code
      weight
    }
  }
`;

export const q_list = gql`
  query inventories ($limit: Int, $offset: Int, $filter: InventoryFilterInput, $sort: [InventorySortInput!]) {
    list: inventories (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      id
      model_code
      description
      product_category
      hsn_code
    }
  },
`;
