import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  x1: yup.number().required(),
  y1: yup.number().required(),
  x2: yup.number().required(),
  y2: yup.number().required(),
  strokewidth: yup.number().required(),
  strokecolor: yup.string().required().default('#000000'),
});

const DialTemplateLineForm = ({ history, match }) => {
  const isUpdateForm = match.params.id_line != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id_line }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    fields.strokecolor = fields.strokecolor?.trim();
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id_line, input: fields }})
      : await createMutation({ variables: { input: fields, id_dial_template: match.params.id } });
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.dial_template_line ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Line`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="x1" component={Form.Input} label="Line start X (mm)" type="number" />
              <SemanticField name="y1" component={Form.Input} label="Line start Y (mm)" type="number" />
              <SemanticField name="x2" component={Form.Input} label="Line end X (mm)" type="number" />
              <SemanticField name="y2" component={Form.Input} label="Line end Y (mm)" type="number" />
              <SemanticField name="strokewidth" component={Form.Input} label="Stroke width (mm)" type="number" />
              <SemanticField name="strokecolor" component={Form.Input} label="Stroke color" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default DialTemplateLineForm;