import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Container, Form, Header, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader.jsx';
import PropertiesTable from 'src/misc/PropertiesTable.jsx';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries.js';

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  order_date: yup.date().required().default(() => dayjs().format('YYYY-MM-DD')),
  id_purchase_order: yup.string().required(),
  purchase_order_date: yup.date().required().default(() => dayjs().format('YYYY-MM-DD')),
  id_currency: yup.string().required(),
  customer_notes: yup.string().nullable(),
  production_notes: yup.string().nullable(),
  id_customer: yup.string().required(),
  id_address: yup.string().required(),
});

const AddressDropdown = ({ formData, id_customer, name, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    const customer = formData.customers.filter(({ id }) => id === id_customer)[0];
    const addresses = customer?.addresses ?? [];
    setFilteredOptions(addresses.map(({ id, ...address }) => ({ 
      key: id, 
      value: id, 
      text: address.street, 
      content: (
        <PropertiesTable
          tableProps={{ basic: 'very', celled: true, compact: 'very' }}
          items={[
            { key: 'Street', value: address.street },
            { key: 'City', value: address.city },
            { key: 'Zipcode', value: address.zipcode },
            { key: 'District', value: address.district },
            { key: 'State', value: address.state },
            { key: 'Country', value: address.country },
          ]}
        />)
    })));
    setFieldValue(name, addresses[0]?.id);
  }, [formData, name, id_customer, setFilteredOptions, setFieldValue]);

  return (
    <SemanticField name={name} component={Form.Dropdown} {...props} options={filteredOptions} />
  );
}

const SalesOrderForm = ({ history, match }) => {
  const isUpdateForm = match.params.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id }, skip: !isUpdateForm });
  const formDataQuery = useQuery(QUERIES.q_form);
  
  if (formDataQuery.loading || updateQuery?.loading) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const currencyOptions = formDataQuery.data.currencies.map(({ id, name }) => ({ key: id, value: id, text: name }));
  const customerOptions = formDataQuery.data.customers.map(({ id, name }) => ({ key: id, value: id, text: name }));

  const handleSubmit = async (fields) => {
    fields.customer_notes = fields.customer_notes ?? null;
    fields.production_notes = fields.production_notes ?? null;
    if (isUpdateForm) {
      await updateMutation({ variables: { id: match.params.id , input: { ...fields, id: undefined } }});
      history.go(-1);
    } else {
      const { data } = await createMutation({ variables: { input: fields } });
      history.push(`./${data.sales_order_create.id}`);
    }
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.sales_order ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Sales Order`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              {!isUpdateForm && <SemanticField fast name="id" component={Form.Input} label="Sales Order #" width={8} />}
              <SemanticField fast name="order_date" label="Order date" width={8} component={Form.Input} type="date" />
              <SemanticField fast name="id_purchase_order" component={Form.Input} label="PO #" />
              <SemanticField fast name="purchase_order_date" label="PO date" component={Form.Input} type="date" fluid />
              <SemanticField fast name="id_currency" fluid selection component={Form.Dropdown} label="Currency" options={currencyOptions} />
              <SemanticField fast name="customer_notes" component={Form.TextArea} label="Customer Notes" />
              <SemanticField fast name="production_notes" component={Form.TextArea} label="Production Notes" />
              <Header as='h2'>Customer details</Header>
                <SemanticField fast name="id_customer" component={Form.Dropdown} fluid search selection label="Billing Customer" options={customerOptions} />
                <AddressDropdown name="id_address" fluid search selection label="Address" formData={formDataQuery.data} id_customer={formikProps.values.id_customer} />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default SalesOrderForm;