// Apollo imports
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import App from './routes';

const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });
const httpLink = new HttpLink({
  uri: `/api/graphql`,
  credentials: 'same-origin',
});

const errLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError?.extensions?.code === 'start-failed' 
    || networkError?.extensions?.code === 'validation-failed' 
    || graphQLErrors?.filter(
        err => err?.extensions?.code === 'access-denied'
      ).length > 0 
  ) {
    history.push(`/login?redirect_uri=${window.location.pathname.replace('/webapp', '')}`);
    return;
  }
  if (graphQLErrors == null)  return;
  else {
    const err = graphQLErrors[0];
    let message = `GRAPHQL ERROR: ${err.message}`;
    if (err.extensions && err.extensions.internal)  message += `\n${err.extensions.internal.error.message}`;
    console.error(message);
  }
});

// Mark scroll position for restoration on back
let prevLocation = window.location.pathname;
history.listen((location, action) => {
  if (prevLocation != null) sessionStorage.setItem(`scroll_${prevLocation}`, window.pageYOffset);
  if (action === 'POP') {
    const scrollY = location == null ? 0 : sessionStorage.getItem(`scroll_${location.pathname}`) || 0;
    window.scrollTo(0, scrollY);
  } else {
    window.scrollTo(0, 0);
  }
  prevLocation = location.pathname;
})

// Instantiate client
const client = new ApolloClient({
  link: from([errLink, httpLink]),
  cache: new InMemoryCache()
});

client.defaultOptions = {
  query: { fetchPolicy: 'cache-and-network' },
  watchQuery: { fetchPolicy: 'cache-and-network' }
};

ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
);