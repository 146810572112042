import { useEffect } from "react";

const { createUseStyles } = require("react-jss");


const useStyles = createUseStyles({
  headerRow: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem',
    backgroundColor: 'hsl(0,0%,80%)',
    '&>*': { flex: '0 0 auto' },
  },
  title: {
    height: '100%',
    textAlign: 'center',
    whiteSpace: 'pre-line',
    '&>h2': {
      marginBottom: '0',
      fontWeight: 'bold',
    },
  },
  rightAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&>*': { flex: '0 0 auto' },
  },
});

const PageHeader = ({ title, subtitle, leftAction, rightAction }) => {
  const classes = useStyles();
  useEffect(() => {
    document.title = title;
  }, [title]);
  
  return (
    <div className={classes.headerRow}>
      <div className={classes.leftAction}>
        {leftAction}
      </div>
      <div className={classes.title}>
        <h2>{title}</h2>
        <div>{subtitle}</div>
      </div>
      <div className={classes.rightAction}>
        {rightAction}
      </div>
    </div>
  );
}

export default PageHeader;
