import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers.jsx';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  model_code: yup.string().required(),
  description: yup.string().required(),
  hsn_code: yup.string().required(),
  weight: yup.number().required(),
});

const AddressForm = ({ history, match }) => {
  const isUpdateForm = match.params.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id, input: fields }})
      : await createMutation({ variables: { input: fields } })
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.inventory ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Inventory`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="model_code" component={Form.Input} label="Model code" />
              <SemanticField name="description" component={Form.TextArea} label="Description" autoHeight rows={1} />
              <SemanticField name="hsn_code" component={Form.Input} type="text" label="HSN code" />
              <SemanticField name="weight" component={Form.Input} type="number" min="0" step="0.01" label="Weight" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default AddressForm;