import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import List from './List';
import Detail from './Detail';

const DispatchRoute = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${path}/`} component={List} />
        <Route exact path={`${path}/:id`} component={Detail} />
      </Switch>
    </>
  );
}

export default DispatchRoute;