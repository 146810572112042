import { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const DialTemplateList = ({ match, history }) => {
  const user = useContext(UserContext);
  return (
    <>
      <PageHeader title="Dial Templates" 
        leftAction={user.isDialPrinting && <Button onClick={() => history.push(`${match.url}/create`)}>New</Button>}
      />
      <CollectionList
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`${match.url}/${id}`)}
        tableColumns={[
          { key: 'd', value: 'DIAL (MM)', width: '20%' },
          { key: 'notes', value: 'NOTES', width: '80%' },
        ]}
        mapDataRowToTable={dial_template => ({
          ...dial_template,
          key: dial_template.id,
        })}
        defaultSortOrder={[{ notes: 'ASC' }, { d: 'ASC' }]}
        defaultFilters={{ notes: '' }}
        filters={[
          { key: 'notes', label: 'NOTES', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { };
          if (!isEmptyString(filterOptions.notes))   queryFilters.notes = filterOptions.notes;
          return queryFilters;
        }}
      />
    </>
  )
};
export default DialTemplateList;