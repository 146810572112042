import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Header, Message, Table } from 'semantic-ui-react';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const ScannerPage = () => {
  const user = useContext(UserContext);
  const [scannedList, setScannedList] = useState([]);
  const [scannerStation, setScannerStation] = useState('');
  const [scanValue, setScanValue] = useState('');
  const [error, setError] = useState(null);
  const [m_scan] = useMutation(QUERIES.m_gauge_line_gauge_barcode_scan);

  const handleScan = async (e, { value }) => {
    setScanValue(value);
    if (value.endsWith('\n')) {
      try {
        await m_scan({ variables: { id_gauge: value.trim(), id_employee: user.id, station: scannerStation }})
        setScannedList([...scannedList, value]);
        setError(null);
      } catch (error) {
        setError(`${value} - ${error.graphQLErrors[0].message}`);
      }
      setScanValue('');
    }
  }

  useEffect(() => setScannedList([]), [scannerStation]); // Clear the scanned gauges list when the scanning station changes

  const stationOptions = [];
  if (user.isAssembly)  stationOptions.push({ key: 'ASSEMBLY', value: 'ASSEMBLY', text: 'Assembly' });
  if (user.isAssembly)  stationOptions.push({ key: 'ANGLE_CHECK', value: 'ANGLE_CHECK', text: 'Angle Check' });
  if (user.isFinishing)  stationOptions.push({ key: 'FINISHING', value: 'FINISHING', text: 'Finishing' });
  if (user.isQc)  stationOptions.push({ key: 'QC', value: 'QC', text: 'QC' });

  if (scannerStation === '')  setScannerStation(stationOptions[0].value);

  return (
    <Container style={{ paddingTop: '48px' }}>
      <Form>
        <Header as="h2">Scan Gauges</Header>
        <Form.Group>
          <Form.Dropdown label="Stage" value={scannerStation} onChange={(e, { value }) => setScannerStation(value)} selection options={stationOptions} width={3} />
          <Form.TextArea label="Scan gauge" value={scanValue} onChange={handleScan} width={9} rows={1} />
        </Form.Group>
      </Form>
      { error && <Message negative content={error} /> }
      <Table basic="very">
        <Table.Body>
          { scannedList.map(gauge => 
            <Table.Row key={gauge}>
              <Table.Cell><Link to={`/documents/sales_orders/${gauge.split('-')[0]}`}>{gauge}</Link></Table.Cell>
            </Table.Row>) 
          }
        </Table.Body>
      </Table>
    </Container>
  );
}

export default ScannerPage;