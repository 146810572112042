import { useMutation, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Header, Icon } from 'semantic-ui-react';
import DelayedLoader from 'src/misc/DelayedLoader';
import PropertiesTable from 'src/misc/PropertiesTable';
import TitledSegment from 'src/misc/TitledSegment';
import { UserContext } from 'src/routes/index.jsx';
import AddressDetails from './AddressDetails';
import * as QUERIES from './queries.js';
import PageHeader from 'src/misc/PageHeader';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 868px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  addressContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&>*': { 
      flex: '0 0 auto',
      marginRight: '32px',
      marginBottom: '16px',
      '&:not(button)': { width: '300px' },
    },
  },
  mainContainer: {
    '&>*': {
      padding: '3em 3em',
    },
    '&>:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&>:nth-child(2n+3)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});

const CustomerDetailsPage = ({ match, history }) => {
  const user = useContext(UserContext);
  const classes = useStyles();
  const [m_delete] = useMutation(QUERIES.m_delete, { variables: { id: match.params.id }});

  const { data, error, refetch } = useQuery(QUERIES.q_detail, {
    variables: { id: match.params.id }
  })

  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`;
  const customer = data.customer;

  return (
    <>
      <PageHeader title={`Customer: ${customer.name}`} />
      <div className={classes.mainContainer}>
        <div className={classes.flexContainer}>
          <div className={classes.details}>
            <PropertiesTable
              tableProps={{ basic: 'very', celled: true, compact: 'very' }}
              items={[
                { key: 'ID', value: customer.id },
                { key: 'GSTIN', value: customer.gstin },
                { key: 'TIN', value: customer.tin },
                { key: 'Website', value: customer.website },
                { key: 'Email', value: customer.email },
                { key: 'Phone number', value: customer.phone_number },
                { key: 'Label name', value: customer.label_name },
                { key: 'Label website', value: customer.label_website },
                { key: 'Label template #', value: customer.id_label_template },
                { key: 'Document name', value: customer.document_name },
              ]}
            />
          </div>
          <div className={classes.actions}>
            <TitledSegment title='Actions' >
              { user.isSales && 
                <button onClick={() => history.push(`${match.url}/edit`)}>
                  <Icon name='edit' />
                  Edit
                </button> 
              } 
              { user.isSales && 
                <button onClick={() => window.confirm('Confirm deletion?') && m_delete() && history.push('./')}>
                  <Icon color="red" name="trash" />
                  Delete
                </button>
              }
            </TitledSegment>
          </div>
        </div>
        <div>
          <h2>Addresses</h2>
          <div className={classes.addressContainer}>
            { customer.addresses.map(address => <AddressDetails key={address.id} address={address} refetchQuery={refetch} />) }
            { user.isSales && 
              <Button onClick={() => history.push(`${match.url}/address/create`)} icon labelPosition='left'>
                <Icon name='add' />
                New address
              </Button>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerDetailsPage;