import { useMutation } from '@apollo/client';
import React from 'react';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { useForm } from 'src/misc/hooks.jsx';
import * as QUERIES from './queries.js';

const DialogDuplicateLine = ({ idLineFrom, setIdLineFrom, onComplete }) => {
  const initialValues = { id_line_to: '', quantity: 1 };
  const [m_sales_order_line_duplicate] = useMutation(QUERIES.m_sales_order_line_duplicate);
  const [formValues, setFormValues, , , formProps] = useForm(initialValues);
  const handleReset = () => { 
    setFormValues(initialValues); 
    setIdLineFrom(null); 
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      formValues.quantity = parseInt(formValues.quantity);
      const result = await m_sales_order_line_duplicate({ variables: { ...formValues, id_line_from: idLineFrom } });
      onComplete?.(result.data.sales_order_line_duplicate)
    } catch (e) {
      window.alert(e);
    } finally {
      setFormValues(initialValues);
      setIdLineFrom(null);
    }
  }
  return (
    <Modal dimmer="blurring" open={idLineFrom!=null} onClose={handleReset} size="mini">
      <Modal.Header>Duplicate line {idLineFrom}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input label='Line to' { ...formProps.id_line_to } type="text" required />
          <Form.Input label='Quantity' { ...formProps.quantity } type="number" step="1" required min="1" />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' onClick={handleReset} ><Icon name='remove' />Discard</Button>
        <Button color='green' onClick={handleSubmit} ><Icon name='checkmark' />Ok</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DialogDuplicateLine;