import React from 'react';
import { Route, Switch, useHistory } from "react-router-dom";
import DelayedLoader from 'src/misc/DelayedLoader';
import LoginRoute from './Login';
import NavBar from './NavBar';
import AppsRoute from './apps';
import DashboardsRoute from './dashboards';
import DocumentsRoute from './documents';
import MastersRoute from './masters';
import ReportsRoute from './reports';


export const UserContext = React.createContext();
const setRoles = ({ roles, ...user }) => ({
  ...user,
  roles,
  isAdmin: roles.includes('admin'),
  isSales: roles.includes('admin') || roles.includes('sales'),
  isPurchase: roles.includes('admin') || roles.includes('purchase'),
  isAccounts: roles.includes('admin') || roles.includes('accounts'),
  isAssembly: roles.includes('admin') || roles.includes('assembly'),
  isFinishing: roles.includes('admin') || roles.includes('finishing'),
  isQc: roles.includes('admin') || roles.includes('qc'),
  isDispatch: roles.includes('admin') || roles.includes('dispatch'),
  isProduction: roles.includes('admin') || roles.includes('production'),
  isDialPrinting: roles.includes('admin') || roles.includes('dial-printing'),
});

const App = () => {
  const history = useHistory();
  return (
    <Switch>
      <Route path="/login" exact component={LoginRoute} />
      <Route path="/dashboards" component={DashboardsRoute} />
      <Route path="/" render={() => {
        const { employee: user, expires } = JSON.parse(localStorage.getItem('hirlekarprecision.erp.webapp.user') ?? '{}');
        const expiresTime = new Date(expires).getTime();
        if (!user || !expires || (Date.now() - expiresTime) > 0) {
          history.push(`/login?redirect_uri=${window.location.pathname.replace('/webapp', '')}`);
          return <DelayedLoader />
        } else 
          return (
            <UserContext.Provider value={setRoles(user)}>
              <NavBar />
              <div style={{ margin: '48px 0' }}>
                <Switch>
                  <Route path="/apps" component={AppsRoute} />
                  <Route path="/documents" component={DocumentsRoute} />
                  <Route path="/masters" component={MastersRoute} />
                  <Route path="/reports" component={ReportsRoute} />
                </Switch>
              </div>
            </UserContext.Provider>
          );
      }} />
    </Switch>
  );
}

export default App;
