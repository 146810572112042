import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import SalesOrderRoute from './sales_order';
import InvoiceRoute from './invoice';

const DocumentsRoute = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.url}/sales_order`} component={SalesOrderRoute} />
        <Route path={`${match.url}/invoice`} component={InvoiceRoute} />
      </Switch>
    </>
  );
}

export default DocumentsRoute;