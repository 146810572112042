import gql from 'graphql-tag.macro';

const f_customer_scalar_details = gql`
  fragment customer_scalar_details_fragment on Customer {
    id
    name
    website
    email
    phone_number
    gstin
    tin
    label_name
    label_website
    id_label_template
    document_name
  }
`;

const f_customer_address_scalar_details = gql`
  fragment customer_address_scalar_details_fragment on CustomerAddress {
    id
    street
    city
    state
    district
    country
    continent
    zipcode
  }
`;

// COMPANY QUERIES

export const q_detail = gql`
query customer ($id: ID!) {
  customer (id: $id) {
    addresses {
      id
      street
      city
      state
      district
      country
      zipcode
      continent
    }
    ...customer_scalar_details_fragment
  },
  ${f_customer_scalar_details},
}`;

export const q_update = gql`
query customer ($id: ID!) {
  customer (id: $id) {
    ...customer_scalar_details_fragment
  },
  ${f_customer_scalar_details},
}`;

export const m_delete = gql`
mutation customer_delete ($id: ID!) {
  customer_delete (id: $id) {
    id
  }
}`;

export const m_update = gql`
mutation customer_update ($id: ID!, $input: CustomerUpdateInput!) {
  customer_update (id: $id, input: $input) {
    id
  }
}`;

export const m_create = gql`
mutation customer_create ($input: CustomerCreateInput!) {
  customer_create (input: $input) {
    id
  }
}`;

export const q_list = gql`
query customers ($limit: Int, $offset: Int, $filter: CustomerFilterInput, $sort: [CustomerSortInput!]) {
  list: customers (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
    id
    name
  }
}`;


// ADDRESS QUERIES

export const m_customer_address_create = gql`
mutation customer_address_create ($input: CustomerAddressCreateInput!){ 
  customer_address_create (input: $input) {
    id
  }
}`;

export const m_customer_address_delete = gql`
mutation customer_address_delete ($id: ID!){
  customer_address_delete (id: $id) { 
    id
  }
}`;

export const m_customer_address_update = gql`
mutation customer_address_update ($id: ID!, $input: CustomerAddressUpdateInput!){
  customer_address_update (id: $id, input: $input) {
    id
  }
}`;

// Query used for prefilling address update form with current values
export const q_customer_address_update = gql`
query update_customer_address($id: ID!) {
  customer_address (id: $id) {
    ...customer_address_scalar_details_fragment
  },
  ${f_customer_address_scalar_details},
}`;