import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DispatchRoute from './dispatch';

const DashboardsRoute = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.url}/dispatch`} component={DispatchRoute} />
      </Switch>
    </>
  );
}

export default DashboardsRoute;