import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  tag_numbers: yup.string().nullable(),
  quantity: yup.number().integer().required().default(1).min(1),
});

const DialogGaugeCreate = ({ open, setOpen, onComplete }) => {
  const [createMutation] = useMutation(QUERIES.m_gauge_create);
  
  const handleReset = () => { 
    setOpen(null); 
  }
  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    fields.tag_numbers = fields.tag_numbers?.trim().split(/\r?\n/) ?? [];
    fields.id_gauge_line = open?.id_gauge_line;
    const { data } = await createMutation({ variables: { input: fields } });
    setOpen(null);
    onComplete && onComplete(data);
  }
  const initialValues = validationSchema.default();

  return (
    <Modal dimmer="blurring" open={open!=null} onClose={handleReset} size="mini">
      <Modal.Header>Create Gauges</Modal.Header>
      <Modal.Content>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset} validationSchema={validationSchema}>
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="quantity" component={Form.Input} label="Quantity" />
              <SemanticField name="tag_numbers" component={Form.TextArea} rows={5} label="Tag numbers" />
              <Button basic color='red' type="reset"><Icon name='remove' />Discard</Button>
              <Button color='green' type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}><Icon name='checkmark' />Ok</Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

export default DialogGaugeCreate;