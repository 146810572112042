import gql from 'graphql-tag.macro';
import { f_scalar_details as f_arc_scalar_details } from './arc/queries';
import { f_scalar_details as f_text_scalar_details } from './text/queries';
import { f_scalar_details as f_curved_text_scalar_details } from './curved_text/queries';
import { f_scalar_details as f_image_scalar_details } from './image/queries';
import { f_scalar_details as f_line_scalar_details } from './line/queries';
import { f_scalar_details as f_scale_scalar_details } from './scale/queries';

const f_scalar_details = gql`
  fragment dial_template_scalar_details_fragment on DialTemplate {
    id
    d
    fg_color
    bg_color
    has_decimal_commas
    has_square_markings
    pinion_y_offset
    porting_arrow_w
    porting_arrow_h
    porting_arrow_y
    porting_x
    porting_y
    porting_d
    unit_text_x
    unit_text_y
    unit_text_angle
    unit_text_fontname
    unit_text_fontsize
    unit_text_draw
    notes
  }
`;

export const m_create = gql`
  mutation dial_template_create ($input: DialTemplateCreateInput!) {
    dial_template_create (input: $input) {
      id
    }
  }
`;

export const m_delete = gql`
  mutation dial_template_delete ($id: ID!) {
    dial_template_delete (id: $id) {
      id
    }
  }
`;

export const m_update = gql`
  mutation dial_template_update ($id: ID!, $input: DialTemplateUpdateInput!) {
    dial_template_update (id: $id, input: $input) {
      id
    }
  }
`;

export const m_dial_template_duplicate  = gql`
  mutation dial_template_duplicate ($id: ID!) {
    dial_template_duplicate (id: $id)
  }
`;

export const q_update = gql`
  query dial_template_update ($id: ID!) {
    dial_template (id: $id) {
      ...dial_template_scalar_details_fragment
    }
  }
  ${f_scalar_details}
`;

export const q_list = gql`
  query dial_templates ($limit: Int, $offset: Int, $filter: DialTemplateFilterInput, $sort: [DialTemplateSortInput!]) {
    list: dial_templates (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      id
      d
      notes
    }
  }
`;

export const q_detail = gql`
  query dial_template ($id: ID!) {
    dial_template (id: $id) {
      ...dial_template_scalar_details_fragment
      images {
        ...dial_template_image_scalar_details_fragment
      }
      arcs {
        ...dial_template_arc_scalar_details_fragment
      }
      lines {
        ...dial_template_line_scalar_details_fragment
      }
      texts {
        ...dial_template_text_scalar_details_fragment
      }
      curved_texts {
        ...dial_template_curved_text_scalar_details_fragment
      }
      scales {
        ...dial_template_scale_scalar_details_fragment
      }
    }
  },
  ${f_scalar_details},
  ${f_arc_scalar_details},
  ${f_text_scalar_details},
  ${f_curved_text_scalar_details},
  ${f_image_scalar_details},
  ${f_line_scalar_details},
  ${f_scale_scalar_details},
`;
