import dayjs from 'dayjs';
import numeral from 'numeral';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Button } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const SalesOrderList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const user = useContext(UserContext);
  return (
    <>
      <PageHeader title="Sales Orders" 
        leftAction={user.isSales && <Button onClick={() => history.push(`${match.url}/create`)}>New</Button>}
      />
      <CollectionList
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`${match.url}/${id}`)}
        tableColumns={[
          { key: 'order_date', value: 'DATE', width: '8%', sortable: true },
          { key: 'id', value: 'OA #', width: '8%', sortable: true },
          { key: 'customer.name', value: 'CUSTOMER', width: '21%' },
          { key: 'purchase_order_date', value: 'PO DATE', width: '8%' },
          { key: 'id_purchase_order', value: 'PO #', width: '15%' },
          { key: 'id_invoices', value: 'INVOICES', width: '15%', whiteSpace: 'pre' },
          { key: 'status', value: 'STATUS', width: '10%' },
          { key: 'quantity', value: 'QTY', width: '5%' },
          { key: 'basic_amount', value: 'AMOUNT', width: '10%', textAlign: 'right', hidden: !user.isSales && !user.isAccounts && !user.isOwner  },
        ]}
        mapDataRowToTable={sales_order => ({
          ...sales_order,
          key: sales_order.id,
          'customer.name': sales_order.customer.name,
          id_invoices: sales_order.id_invoices.join('\n'),
          order_date: dayjs(sales_order.order_date).format('DD MMM YY'),
          purchase_order_date: dayjs(sales_order.purchase_order_date).format('DD MMM YY'),
          basic_amount: `${sales_order.currency.symbol} ${numeral(sales_order.basic_amount).format('0,0.00')}`,
        })}
        defaultSortOrder={[{ 'order_date': 'DESC' }, { 'id': 'DESC' }]}
        defaultFilters={{
          id: '', idPurchaseOrder: '', idInvoice: '', customer: '', series: '', region: 'All', 
          status: ['Active','Shortclosed','Fulfilled'], dateFrom: '', dateTo: '',
        }}
        filters={[
          { key: 'id', label: 'OA #', type: 'text' },
          { key: 'idPurchaseOrder', label: 'PO #', type: 'text' },
          { key: 'idInvoice', label: 'INVOICE', type: 'text' },
          { key: 'customer', label: 'CUSTOMER', type: 'text' },
          { key: 'series', label: 'SERIES', type: 'text' },
          { key: 'region', label: 'REGION', type: 'radio', options: ['Export', 'Domestic', 'All'] },
          { 
            key: 'status', label: 'STATUS', type: 'select', multiple: true, 
            options: ['Active','Shortclosed','Fulfilled'], 
          },
          { key: 'dateFrom', label: 'DATE FROM', type: 'date' },
          { key: 'dateTo', label: 'DATE TO', type: 'date' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { status: filterOptions.status };
          if (!isEmptyString(filterOptions.id))   queryFilters.id = filterOptions.id;
          if (!isEmptyString(filterOptions.customer))   queryFilters.customer = filterOptions.customer;
          if (!isEmptyString(filterOptions.series))   queryFilters.series = filterOptions.series;
          if (filterOptions.region === 'Domestic')  queryFilters.region = 'DOMESTIC';
          else if (filterOptions.region === 'Export') queryFilters.region = 'EXPORT';
          if (!isEmptyString(filterOptions.dateFrom)) queryFilters.date_from = filterOptions.dateFrom;
          if (!isEmptyString(filterOptions.dateTo)) queryFilters.date_to = filterOptions.dateTo;
          if (!isEmptyString(filterOptions.idPurchaseOrder))   queryFilters.id_purchase_order = filterOptions.idPurchaseOrder;
          if (!isEmptyString(filterOptions.idInvoice)) queryFilters.id_invoice = filterOptions.idInvoice;
          return queryFilters;
        }}
      />
    </>
  )
};
export default SalesOrderList;