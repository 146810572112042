import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const InventoryList = ({ match, history }) => {
  const [m_delete] = useMutation(QUERIES.m_delete);
  const user = useContext(UserContext);
  return (
    <>
      <PageHeader title="Inventories" 
        leftAction={user.isSales && <Button onClick={() => history.push(`${match.url}/create`)}>New</Button>}
      />
      <CollectionList
        query={QUERIES.q_list}
        tableColumns={[
          { key: 'model_code', value: 'Model code', width: '25%' },
          { key: 'description', value: 'Description', width: '35' },
          { key: 'category', value: 'Category', width: '20%' },
          { key: 'hsn_code', value: 'HSN', width: '10%' },
          { key: 'line_actions', sortable: false, value: '', width: '10%', hidden: !user.isSales },
        ]}
        mapDataRowToTable={inventory => ({
          ...inventory,
          key: inventory.id,
          line_actions: (
            <div>
              <Button onClick={() => history.push(`${match.url}/${inventory.id}/edit`)} style={{ background: 'none' }} icon >
                <Icon name='pencil' />
              </Button>
              <Button style={{ background: 'none' }} icon
                onClick={async () => {
                  if (window.confirm('Confirm deletion? Cannot delete if used anywhere.')) {
                    await m_delete({ variables: { id: inventory.id }});
                    history.go(0);
                  }
                }}
              >
                <Icon color="red" name="trash" />
              </Button>
            </div>
          ),
        })}
        defaultSortOrder={[{ 'model_code': 'ASC' }]}
        defaultFilters={{ description: '' }}
        filters={[
          { key: 'description', label: 'DESCRIPTION', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = {};
          if (!isEmptyString(filterOptions.description))   queryFilters.description = filterOptions.description;
          return queryFilters;
        }}
      />
    </>
  )
};
export default InventoryList;