import gql from 'graphql-tag.macro';

const f_series_scalar_details = gql`
  fragment GaugeSeriesDetailsFragment on GaugeSeries {
    id
    name
    type
    ip_code
    max_accuracy
    max_temperature
    max_pressure
    has_dual_dials
  }
`;

export const m_create = gql`
  mutation gauge_series_create ($input: GaugeSeriesCreateInput!) {
    gauge_series_create (input: $input) {
      id
    },
  }
`;

export const m_delete = gql`
  mutation gauge_series_delete ($id: ID!) {
    gauge_series_delete (id: $id) {
      id
    }
  }
`;

export const m_update = gql`
  mutation gauge_series_update ($id: ID!, $input: GaugeSeriesUpdateInput!) {
    gauge_series_update (id: $id, input: $input) {
      id
    },
  }
`;

export const q_update = gql`
  query gauge_series_update ($id: ID!) {
    gauge_series (id: $id) {
      ...GaugeSeriesDetailsFragment
    },
  }
  ${f_series_scalar_details},
`;

export const q_list = gql`
  query gauge_serieses ($limit: Int, $offset: Int, $filter: GaugeSeriesFilterInput, $sort: [GaugeSeriesSortInput!]) {
    list: gauge_serieses (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      ...GaugeSeriesDetailsFragment
    },
  }
  ${f_series_scalar_details}
`;