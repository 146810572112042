import gql from "graphql-tag.macro";

export const m_inventory_line_create = gql`
  mutation inventory_sale_line_create ($input: InventorySaleLineCreateInput!) {
    inventory_sale_line_create (input: $input) {
      id
    }
  },
`;

export const m_inventory_line_delete = gql`
  mutation inventory_sale_line_delete ($id: ID!) {
    inventory_sale_line_delete (id: $id) {
      id
    }
  }
`;

export const m_inventory_line_update = gql`
  mutation inventory_sale_line_update ($id: ID!, $input: InventorySaleLineUpdateInput!) {
    inventory_sale_line_update (id: $id, input: $input) {
      id
    }
  },
`;

export const q_inventory_line_update = gql`
  query inventory_sale_line_update ($id: ID!) {
    inventory_sale_line (id: $id) {
      line
      id_invoice
      end_customer_po
      article_number
      quantity
      price
      customer_code
      label_notes
      notes
      id_inventory
    }
  },
`;

export const q_form_inventory_line = gql`
  query inventory_sale_line_form {
    inventories (limit: 10000, sort: { model_code: ASC }) {
      id
      model_code
      description
    }
  }
`;

export const q_detail_inventory_line = gql`
  query inventory_sale_line_details ($id: ID!) {
    inventory_sale_line (id: $id) {
      id
      line
      id_sales_order
      sales_order {
        id
        currency {
          symbol
        }
      }
      invoice {
        id
        status
      }
      inventory {
        id
        model_code
      }
      customer_code
      end_customer_po
      price
      quantity
      amount
      notes
    }
  }
`;