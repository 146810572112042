import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import BoxRoute from './box';
import InventoryRoute from './inventory';
import CustomerRoute from './customer';
import UnitRoute from './unit';
import ScaleRoute from './scale';
import SeriesRoute from './series';
import DialTemplateRoute from './dial_template';

const MastersRoute = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.url}/customer`} component={CustomerRoute} />
        <Route path={`${match.url}/inventory`} component={InventoryRoute} />
        <Route path={`${match.url}/box`} component={BoxRoute} />
        <Route path={`${match.url}/unit`} component={UnitRoute} />
        <Route path={`${match.url}/scale`} component={ScaleRoute} />
        <Route path={`${match.url}/series`} component={SeriesRoute} />
        <Route path={`${match.url}/dial_template`} component={DialTemplateRoute} />
      </Switch>
    </>
  );
}

export default MastersRoute;