import dayjs from 'dayjs';
import { useHistory, useRouteMatch } from 'react-router';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader.jsx';
import { isEmptyString } from 'src/misc/validators.js';
import * as QUERIES from './queries.js';

const DispatchList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  return (
    <>
      <PageHeader title="Dispatches"/>
      <CollectionList
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`${match.url}/${id}`)}
        tableColumns={[
          { key: 'date', value: 'DATE', width: '12%' },
          { key: 'id', value: 'ID', width: '12%' },
          { key: 'customer', value: 'CUSTOMER' },
          { key: 'qty_assigned_box', value: 'ASSIGNED BOX', width: '12%' },
          { key: 'qty_packed', value: 'PACKED', width: '12%' },
          { key: 'qty_total', value: 'TOTAL QTY', width: '12%' },
        ]}
        mapDataRowToTable={invoice => ({
          ...invoice,
          ...invoice.packing_status,
          key: invoice.id,
          customer: invoice.customer_billing.name,
          date: dayjs(invoice.invoice_date).format('DD MMM YY'),
        })}
        defaultSortOrder={[{ 'date': 'DESC' }, { 'id': 'DESC' }]}
        defaultFilters={{ id: '', customer: '', date_from: '', date_to: '' }}
        filters={[
          { key: 'id', label: 'ID', type: 'text' },
          { key: 'customer', label: 'CUSTOMER', type: 'text' },
          { key: 'date_from', label: 'DATE FROM', type: 'date' },
          { key: 'date_to', label: 'DATE TO', type: 'date' },
        ]}
        mapFiltersToQuery={filterOptions => {
          const queryFilters = { status: 'Production' };
          if (!isEmptyString(filterOptions.id))   queryFilters.id = filterOptions.id;
          if (!isEmptyString(filterOptions.customer))   queryFilters.customer = filterOptions.customer; 
          if (!isEmptyString(filterOptions.date_from)) queryFilters.date_from = filterOptions.date_from;
          if (!isEmptyString(filterOptions.date_to)) queryFilters.date_to = filterOptions.date_to;
          return queryFilters;
        }}
      />
    </>
  )
};
export default DispatchList;