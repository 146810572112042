import { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const CompanyList = ({ match, history }) => {
  const user = useContext(UserContext);
  return (
    <>
      <PageHeader title="Customers" 
        leftAction={user.isSales && <Button onClick={() => history.push(`${match.url}/create`)}>New</Button>}
      />
      <CollectionList
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`${match.url}/${id}`)}
        tableColumns={[
          { key: 'name', value: 'NAME' },
        ]}
        mapDataRowToTable={customer => ({
          ...customer,
          key: customer.id,
        })}
        defaultSortOrder={[{ 'name': 'ASC' }]}
        defaultFilters={{ name: '' }}
        filters={[
          { key: 'name', label: 'NAME', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { };
          if (!isEmptyString(filterOptions.name))   queryFilters.name = filterOptions.name;
          return queryFilters;
        }}
      />
    </>
  )
};
export default CompanyList;