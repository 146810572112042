import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import numeral from 'numeral';
import { useContext, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Grid, Header, Icon, Menu, Portal, Table } from 'semantic-ui-react';
import DataTable from 'src/misc/DataTable';
import DelayedLoader from 'src/misc/DelayedLoader';
import { fragmentNewlines } from "src/misc/helpers";
import PropertiesTable from "src/misc/PropertiesTable";
import TitledSegment from 'src/misc/TitledSegment';
import { isEmptyString } from 'src/misc/validators';
import { UserContext } from 'src/routes/index.jsx';
import DialogAddLines from './DialogAddLines';
import * as QUERIES from './queries.js';
import PageHeader from 'src/misc/PageHeader';

const useStyles = createUseStyles({
  flexContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  statusSubheader: {
    fontSize: '1.2rem',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 868px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  mainContainer: {
    '&>*': {
      padding: '3em 3em',
      '&>h2': {
        color: 'hsl(0, 0%, 40%)',
        fontWeight: 'bold',
        marginBottom: '1em',
      },
    },
    '&>:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&>:nth-child(2n+3)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});

const ChargeRow = ({ description, amount, is_taxable = true, style }) => 
  <Table.Row style={style}>
    <Table.Cell>{`${description}${is_taxable ? '' : ' (Not Taxable)'}`}</Table.Cell>
    <Table.Cell textAlign='right'>{numeral(amount).format('0,0.00')}</Table.Cell>
  </Table.Row>

const InvoiceCharges = ({ invoice }) => 
  <div>
    <h2>Charges</h2>
    <Table collapsing celled basic='very' textAlign="right" >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={12}>Description</Table.HeaderCell>
          <Table.HeaderCell width={4} textAlign="right">{`Amount (${invoice.currency.symbol})`}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <ChargeRow description="Basic amount" amount={invoice.financials.basic_amount} style={{ fontWeight: 'bold' }} />
        { invoice.financials.all_charges.map((charge, i) => <ChargeRow key={i} {...charge} />) }
        <ChargeRow description={`Taxable Amount`} amount={invoice.financials.taxable_amount} style={{ fontWeight: 'bold' }} />
        { invoice.financials.cgst_amount > 0 && <ChargeRow description={`CGST @ ${invoice.cgst}%`} amount={invoice.financials.cgst_amount} /> }
        { invoice.financials.sgst_amount > 0 && <ChargeRow description={`SGST @ ${invoice.sgst}%`} amount={invoice.financials.sgst_amount} /> }
        { invoice.financials.igst_amount > 0 && <ChargeRow description={`IGST @ ${invoice.igst}%`} amount={invoice.financials.igst_amount} /> }
        { invoice.financials.tcs_amount > 0 && <ChargeRow description={`TCS @ ${invoice.financials.tcs}%`} amount={invoice.financials.tcs_amount} /> }
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell><b>Total</b></Table.HeaderCell>
          <Table.HeaderCell textAlign='right'><b>{numeral(invoice.financials.total_amount).format('0,0.00')}</b></Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  </div>


const InvoiceDetails = ({ match }) => {
  const user = useContext(UserContext);
  const classes = useStyles();
  const history = useHistory();
  const [contextMenuState, setContextMenuState] = useState(null);
  const { data, error, refetch } = useQuery(QUERIES.q_detail, { variables: { id: match.params.id }});
  const [m_status_update] = useMutation(QUERIES.m_status_update);
  const [m_finalize] = useMutation(QUERIES.m_finalize);
  const [m_delete_invoice] = useMutation(QUERIES.m_delete, { variables: { id: match.params.id }});
  const [m_invoice_remove_sales_order_line] = useMutation(QUERIES.m_invoice_remove_sales_order_line);
  const [linesDialogOpen, setLinesDialogOpen] = useState(null);
  if (data == null) return <DelayedLoader />
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`
  const invoice = data.invoice;
  const isArchived = !['Sales', 'Production'].includes(invoice.status);

  const handleOpenContexMenu = id_line => event => {
    event.preventDefault();
    setContextMenuState({
      id_line,
      mouseX: event.pageX - 2,
      mouseY: event.pageY - 4,
    });
  };

  const handleClickMenuItem = (action) => async () => {
    if (action === 'delete') {
      if (window.confirm('Confirm removal from invoice?')) {
        await m_invoice_remove_sales_order_line({ variables: { id_line: contextMenuState.id_line }})
        refetch();
      }
      setContextMenuState(null);
    }
  }

  const LineContextMenu = () => {
    if (contextMenuState == null) 
      return null;
    return (
      <Portal open={true} onClose={() => setContextMenuState(null)} >
        <Menu vertical style={{ position: 'absolute', top: contextMenuState.mouseY, left: contextMenuState.mouseX, zIndex: 1000 }}>
          { user.isSales && !isArchived && <Menu.Item onClick={handleClickMenuItem('delete')}>Delete</Menu.Item> }
        </Menu>
      </Portal>
    );
  }

  const handleReleaseToProduction = async () => {
    if (window.confirm('Confirm release to production?')) {
      await m_status_update({ variables: { id: invoice.id, status: 'Production', id_user: user.id }});
      refetch();
    }
  }

  const handleFinalize = async () => {
    const final_id = window.prompt('Please enter final invoice id');
    if (final_id != null && !isEmptyString(final_id)) {
      await m_finalize({ variables: { old_id: invoice.id, new_id: final_id, id_user: user.id }});
      history.replace(`./${final_id}`);
    }
  }

  const handleDelete = async () => {
    if (window.confirm('Confirm deletion?')) {
      await m_delete_invoice({ variables: { id: invoice.id }});
      history.push('./');
    }
  }
  
  return (
    <>
      <LineContextMenu />
      <DialogAddLines open={linesDialogOpen} setOpen={setLinesDialogOpen} onComplete={refetch} />
      <PageHeader title={`Invoice:  ${invoice.id}`} />
      <div className={classes.mainContainer}>
        <div className={classes.flexContainer}>
          <div className={classes.details}>
            <Grid celled='internally'>
              <Grid.Row>
                <Grid.Column width={4}>
                  <PropertiesTable
                    tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                    items={[
                      { key: 'Status', value: invoice.status },
                      { key: 'Date', value: dayjs(invoice.invoice_date).format('DD MMM YY') },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as='h3'>Billing</Header>
                  <Header as={Link} to={`/masters/customer/${invoice.customer_billing.id}`}>{invoice.customer_billing.name}</Header>
                  <PropertiesTable
                    tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                    items={[
                      { key: 'Street', value: invoice.address_billing.street },
                      { key: 'City', value: invoice.address_billing.city },
                      { key: 'Zipcode', value: invoice.address_billing.zipcode },
                      { key: 'District', value: invoice.address_billing.district },
                      { key: 'State', value: invoice.address_billing.state },
                      { key: 'Country', value: invoice.address_billing.country },
                    ]}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as='h3'>Shipping</Header>
                  <Header as={Link} to={`/masters/customer/${invoice.customer_shipping.id}`}>{invoice.customer_shipping.name}</Header>
                  <PropertiesTable
                    tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                    items={[
                      { key: 'Street', value: invoice.address_shipping.street },
                      { key: 'City', value: invoice.address_shipping.city },
                      { key: 'Zipcode', value: invoice.address_shipping.zipcode },
                      { key: 'District', value: invoice.address_shipping.district },
                      { key: 'State', value: invoice.address_shipping.state },
                      { key: 'Country', value: invoice.address_shipping.country },
                    ]}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as='h3'>Freight</Header>
                  <PropertiesTable
                    tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                    items={[
                      { key: 'Freight carrier', value: invoice.freight_carrier },
                      { key: 'Freight mode', value: invoice.freight_mode },
                      { key: 'Freight terms', value: invoice.freight_terms },
                      { key: 'Airway Bill #', value: invoice.airwaybill_number },
                    ]}
                  />
                </Grid.Column>
                {(user.isSales || user.isAccounts || user.isOwner) 
                  ? <Grid.Column width={8}>
                      <Header as='h3'>Business Terms</Header>
                      <PropertiesTable
                        tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                        items={[
                          { key: 'Payment due', value: dayjs(invoice.invoice_date).add(invoice.credit_days, 'days').format('DD MMM YY') },
                          { key: 'Payment terms', value: `${invoice.payment_terms}\n\n${invoice.bank_terms}` },
                          { key: 'Trade terms', value: invoice.trade_terms },
                          { key: 'Insurance terms', value: invoice.insurance_terms },
                        ]}
                      />
                    </Grid.Column>
                  : null
                }
              </Grid.Row>
              {(user.isSales || user.isAccounts || user.isOwner) 
                ? <Grid.Row>
                    <Grid.Column width={8}>
                      <Header as='h3'>E-invoice</Header>
                      <PropertiesTable
                        tableProps={{ basic: 'very', celled: true, compact: 'very', 'fixed': true }}
                        items={[
                          { key: 'Supply type', value: invoice.einv_suptyp },
                          { key: 'IRN', value: invoice.einv_irn },
                          { key: 'IRN date', value: invoice.einv_irn_date == null ? '' : dayjs(invoice.einv_irn_date).format('DD MMM YY') },
                          { key: 'Ewaybill #', value: invoice.einv_ewaybill_num },
                        ]}
                      />
                    </Grid.Column>
                  </Grid.Row>
                : null
              }
              {invoice.notes && <Grid.Row>
                <Grid.Column>
                  <Header as='h4'>Notes</Header>
                  <p>{fragmentNewlines(invoice.notes)}</p>
                </Grid.Column>
              </Grid.Row>}
            </Grid>
          </div>
          <div className={classes.actions}>
            <TitledSegment title='Actions' >
              { user.isSales && !isArchived &&
                <button onClick={() => setLinesDialogOpen({ id_invoice: invoice.id })}>Add sales order lines</button>
              }
              { user.isSales && invoice.status === 'Sales' && 
                <button onClick={handleReleaseToProduction}>Release to production</button> 
              }
              { user.isSales && invoice.status === 'Production' && invoice.id_finalized_by == null &&
                <button onClick={handleFinalize}>Finalize</button>
              }
              { user.isSales && !isArchived && 
                <button onClick={() => history.push(`${match.url}/edit`)}> <Icon name='edit' />Edit</button> 
              }
              { user.isSales && !isArchived &&
                <button onClick={handleDelete}><Icon color="red" name="trash" />Delete</button>
              }
            </TitledSegment>
            <TitledSegment title='Documents'>
              {user.isSales && <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/invoice`)}><Icon name="file pdf" />Invoice</button>}
              {user.isSales && <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/converted-invoice`)}><Icon name="file pdf" />Converted Invoice</button>}
              {user.isSales && <button onClick={() => window.open(`/pdf/einvoice?id=${invoice.id}`)} disabled={invoice.id.startsWith('T') || (invoice.currency.name !== 'INR' && invoice.exchange_rate === 0)}><Icon name="file code" />Einvoice JSON</button>}
              <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/calibration-certificates`)}><Icon name="file pdf" />Calibration Certificates</button>
              <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/test-certificates`)}><Icon name="file pdf" />Test Certificates</button>
              <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/gauge-labels`)}><Icon name="file pdf" />Gauge Labels</button>
              <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/inner-box-labels`)}><Icon name="file pdf" />Inner Box Labels</button>
              <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/outer-box-labels`)}><Icon name="file pdf" />Outer Box Labels</button>
              <button onClick={() => window.open(`/pdf/invoices/${invoice.id}/working-labels`)}><Icon name="file pdf" />Working Labels</button>
            </TitledSegment>
          </div>
        </div>
        <div>
          <h2>Gauges</h2>
          {invoice.gauge_lines.length > 0 && 
            <DataTable
              tableProps={{ fixed: true, celled: true, selectable: true, basic: 'very' }}
              rowProps={{ style: { cursor: 'pointer' }}}
              endReached={true}
              onClick={(id) => history.push(`/documents/sales_order/${id.split('-')[0]}/gauge_line/${id}`)}
              handleContextMenu={handleOpenContexMenu}
              columns={[
                { key: 'id_sales_order', value: 'OA #', width: 1 },
                { key: 'line', value: 'Line', width: 1 },
                { key: 'model_code', value: 'Model code', width: 4 },
                { key: 'id_invoice', value: 'Invoice', width: 2 },
                { key: 'qty_assembled', value: 'Assembly', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_angle_checked', value: 'Angle Check', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_calibrated', value: 'Calibration', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_dial_crosschecked', value: 'Dial Cross', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_switch_crosschecked', value: 'Switch Cross', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_finished', value: 'Finishing', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_checked', value: 'QC', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'qty_packed', value: 'Packing', slanted: true, footer: 'total', textAlign: 'right', type: 'percent', maxValue: 'quantity' },
                { key: 'price', value: `Price (${invoice.currency.symbol})`, width: 1, textAlign: 'right', 
                  format: (val) => numeral(val).format('0,0.00'), hidden: !user.isSales && !user.isAccounts && !user.isOwner  },
                { key: 'quantity', value: 'Quantity', textAlign: 'right', footer: 'total', width: 1 },
                { key: 'amount', value: `Amount (${invoice.currency.symbol})`, width: 1, textAlign: 'right', footer: 'total', 
                  format: (val) => numeral(val).format('0,0.00'), hidden: !user.isSales && !user.isAccounts && !user.isOwner  },
              ]}
              rows={invoice.gauge_lines.map(({ production_status, ...line }) => ({
                key: line.id,
                id_sales_order: line.sales_order.id,
                id_invoice: line.invoice ? line.invoice.id : null,
                ...production_status,
                ...line,
              }))}
            />}
        </div>
        <div>
          <h2>Inventories</h2>
          {invoice.inventory_sale_lines.length > 0 && 
            <DataTable
              tableProps={{ fixed: true, celled: true, selectable: true, basic: 'very' }}
              rowProps={{ style: { cursor: 'pointer' }}}
              endReached={true}
              onClick={(id) => history.push(`/documents/sales_order/${id.split('-')[0]}/inventory_line/${id}`)}
              handleContextMenu={handleOpenContexMenu}
              columns={[
                { key: 'id_sales_order', value: 'OA #', width: 1 },
                { key: 'line', value: 'Line', width: 1 },
                { key: 'model_code', value: 'Model code', width: 4 },
                { key: 'id_invoice', value: 'Invoice', width: 2 },
                { key: 'qty_packed', value: 'Packed', textAlign: 'right', footer: 'total', type: 'percent', maxValue: 'quantity' },
                { key: 'price', value: `Price (${invoice.currency.symbol})`, width: 1, textAlign: 'right', 
                  format: (val) => numeral(val).format('0,0.00'), hidden: !user.isSales && !user.isAccounts && !user.isOwner  },
                { key: 'quantity', value: 'Quantity', textAlign: 'right', footer: 'total', width: 1 },
                { key: 'amount', value: `Amount (${invoice.currency.symbol})`, width: 1, textAlign: 'right', footer: 'total', 
                  format: (val) => numeral(val).format('0,0.00'), hidden: !user.isSales && !user.isAccounts && !user.isOwner },
              ]}
              rows={invoice.inventory_sale_lines.map(({ production_status, ...line }) => ({
                key: line.id,
                id_sales_order: line.sales_order.id,
                id_invoice: line.invoice ? line.invoice.id : null,
                model_code: line.inventory.model_code,
                ...production_status,
                ...line,
              }))}
            />}
        </div>
        {(user.isSales || user.isAccounts || user.isOwner) ? <InvoiceCharges invoice={invoice} /> : null}
      </div>
    </>
  );   
}

export default InvoiceDetails;