import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import DataTable from 'src/misc/DataTable';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const useStyles = createUseStyles({
  container: {
    '&>h2': {
      color: 'hsl(0, 0%, 40%)',
      fontWeight: 'bold',
    },
    '&>*': { margin: '32px 0' },
  },
  properties: {
    '& td': { border: [['none'], '!important'] },
    '& td:nth-child(n+2)': { borderLeft: [['1px solid rgba(34,36,38,0.1)'], '!important'] },

  }
})

const DialTemplateScaleTable = ({ scales, refetchQuery }) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [m_delete] = useMutation(QUERIES.m_delete);
  
  const handleDelete = (id) => {
    if (window.confirm('Confirm deletion?')) {
      m_delete({ variables: { id }}); 
      refetchQuery();
    }
  }
  
  return (
    <div className={classes.container}>
      <h2>Scales</h2>
      <DataTable
        tableProps={{ celled: true, basic: 'very', compact: 'very' }}
        columns={[
          { key: 'scale_number', value: 'Scale #' },
          { key: 'arc', value: 'Arc' },
          { key: 'markings', value: 'Markings' },
          { key: 'texts', value: 'Texts' },
          { key: 'callouts', value: 'Callouts' },
          { key: 'colorbands', value: 'Colorbands' },
          { key: 'unit_texts', value: 'Unit text' },
          { key: 'line_actions', value: '', width: 1, ignoreClick: true, hidden: !user.isDialPrinting },
        ]}
        rows={ scales.map(({ id, ...scale }) => ({
          ...scale,
          key: id,
          arc: <table className={classes.properties}>
            <tbody>
              <tr><td>Rad (mm)</td><td>{scale.arc_rad}</td></tr>
              <tr><td>Arc</td><td>{scale.draw_arc ? '✔️' : '✘'}</td></tr>
              <tr><td>Deadband</td><td>{scale.draw_deadband ? '✔️' : '✘'}</td></tr>
            </tbody>
          </table>,
          markings: <table className={classes.properties}>
            <tbody>
              <tr><td>Minor inner rad (mm)</td><td>{scale.minor_line_inner_rad}</td></tr>
              <tr><td>Minor outer rad (mm)</td><td>{scale.minor_line_outer_rad}</td></tr>
              <tr><td>Strokewidth (mm)</td><td>{scale.minor_strokewidth}</td></tr>
              <tr><td>Major inner rad (mm)</td><td>{scale.major_line_inner_rad}</td></tr>
              <tr><td>Major outer rad (mm)</td><td>{scale.major_line_outer_rad}</td></tr>
              <tr><td>Strokewidth (mm)</td><td>{scale.major_strokewidth}</td></tr>
            </tbody>
          </table>,
          texts: <table className={classes.properties}>
            <tbody>
              <tr><td>Rad (mm)</td><td>{scale.major_text_rad}</td></tr>
              <tr><td>Angle (deg)</td><td>{scale.text_angle}</td></tr>
              <tr><td>Font</td><td>{scale.fontname}</td></tr>
              <tr><td>Font size (mm)</td><td>{scale.fontsize}</td></tr>
              <tr><td>Color</td><td>{scale.strokecolor}</td></tr>
            </tbody>
          </table>,
          callouts: <table className={classes.properties}>
            <tbody>
              <tr><td>Inner rad (mm)</td><td>{scale.callout_line_inner_rad}</td></tr>
              <tr><td>Outer rad (mm)</td><td>{scale.callout_line_outer_rad}</td></tr>
              <tr><td>Text rad (mm)</td><td>{scale.callout_text_rad}</td></tr>
              <tr><td>Strokewidth (mm)</td><td>{scale.callout_strokewidth}</td></tr>
              <tr><td>Font size (mm)</td><td>{scale.callout_fontsize}</td></tr>
              <tr><td>Color</td><td>{scale.callout_strokecolor}</td></tr>
            </tbody>
          </table>,
          colorbands: <table className={classes.properties}>
            <tbody>
              <tr><td>Inner radius (mm)</td><td>{scale.colorband_inner_rad}</td></tr>
              <tr><td>Outer radius (mm)</td><td>{scale.colorband_outer_rad}</td></tr>
              <tr><td>Stroke width (mm)</td><td>{scale.colorband_strokewidth}</td></tr>
              <tr><td>Colorband on top</td><td>{scale.colorband_on_top ? '✔️' : '✘'}</td></tr>
            </tbody>
          </table>,
          unit_texts: <table className={classes.properties}>
            <tbody>
              <tr><td>X (mm)</td><td>{scale.unit_text_x}</td></tr>
              <tr><td>Y (mm)</td><td>{scale.unit_text_y}</td></tr>
              <tr><td>Angle (deg)</td><td>{scale.unit_text_angle}</td></tr>
              <tr><td>Font</td><td>{scale.unit_text_fontname}</td></tr>
              <tr><td>Font size (mm)</td><td>{scale.unit_text_fontsize}</td></tr>
            </tbody>
          </table>,
          line_actions: (
            <div>
              <Button style={{ background: 'none' }} icon onClick={() => history.push(`${match.url}/scale/${id}/edit`)}>
                <Icon name='pencil' />
              </Button>
              <Button style={{ background: 'none' }} icon onClick={() => handleDelete(id)}>
                <Icon color="red" name="trash" />
              </Button>
            </div>
          ),
        }))}
      />
      { user.isDialPrinting && 
        <Button secondary onClick={() => history.push(`${match.url}/create_scale`)} icon labelPosition='left'>
          <Icon name='add' />
          New scale
        </Button>
      }
    </div>
  );
}

export default DialTemplateScaleTable;