import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import ScannerRoute from './scanner';
import DispatchRoute from './dispatch';

const AppsRoute = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${match.url}/scanner`} component={ScannerRoute} />
        <Route path={`${match.url}/dispatch`} component={DispatchRoute} />
      </Switch>
    </>
  );
}

export default AppsRoute;