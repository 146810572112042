import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';
import DataTable from 'src/misc/DataTable.jsx';
import DelayedLoader from 'src/misc/DelayedLoader';
import { fragmentNewlines, precisionRound } from 'src/misc/helpers.jsx';
import PropertiesTable from "src/misc/PropertiesTable.jsx";
import TitledSegment from 'src/misc/TitledSegment';
import { UserContext } from "src/routes/index.jsx";
import DialogGaugeCreate from './DialogGaugeCreate';
import DialogGaugeUpdate from './DialogGaugeUpdate';
import * as QUERIES from './queries';

const useStyles = createUseStyles({
  flexContainer: {
    padding: '3rem', 
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  '@media (min-width: 768px)': {
    flexContainer: { flexWrap: 'nowrap' },
    details: { 
      flex: '0 1 70%',
    },
    actions: {
      justifyContent: 'flex-start',
      flexDirection: 'column',
    },
  },
  '@media (max-width: 768px)': {
    flexContainer: { flexWrap: 'wrap' },
    details: { 
      flex: '1 1 auto',
      marginBottom: '32px',
    },
    actions: {
      flex: '1 1 auto',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
  },
  switchColorbandContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': { flex: '0 1 auto', marginRight: '64px' },
  },
  details: {},
  actions: {
    display: 'flex',
    '&>*': { width: '300px', margin: '12px' }
  },
  routeHeading: { padding: '20px 32px 0 32px' },
  detailItem: {
    padding: '32px',
    '&>h2,&>h3': {
      color: 'hsl(0, 0%, 40%)',
      fontWeight: 'bold',
      marginBottom: '1em',
    },
    '&>button': { marginTop: [['32px'], '!important'] },
    '&:nth-child(2n+2)': {
      backgroundColor: 'hsl(0, 0%, 96%)'
    },
    '&:nth-child(2n+1)': {
      backgroundColor: 'hsl(0, 0%, 88%)'
    }
  }
});


const GaugeLineScales = ({ scales, isArchived, refetchQuery }) => {
  const user = useContext(UserContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [m_delete] = useMutation(QUERIES.m_gauge_line_scale_delete);

  const handleDelete = async (id) => {
    if (window.confirm('Confirm deletion?'))
      await m_delete({ variables: { id }});
    refetchQuery();
  }
  return (
    <div>
      {scales != null && scales.length > 0 && <DataTable
        tableProps={{ compact: 'very', basic: 'very' }}
        columns={[
          { key: 'scale_number', value: '', textAlign: 'right' },
          { key: 'range', value: 'Range', width: 2 },
          { key: 'label_text', value: 'Text', width: 2 },
          { key: 'deadband', value: 'Deadband', width: 2 },
          { key: 'markings', value: 'Markings', },
          { key: 'colorbands', value: 'Colorbands', width: 3 },
          { key: 'line_actions', value: '', width: 2 },
        ]}
        rows={scales.map(({ id, scale, unit, multiplier, colorbands, is_ascending, ...rest }) => ({
          ...rest,
          key: id,
          range: `${precisionRound(scale.range_from*multiplier, 5)} - ${precisionRound(scale.range_to*multiplier, 5)} ${unit.name} ${is_ascending ? 'Ascending' : 'Descending'}`,
          deadband: `${rest.deadband_from} - ${rest.deadband_to} deg`,
          line_actions: (user.isDialPrinting || user.isSales) && !isArchived && (
            <div>
              <Button style={{ background: 'none' }} icon onClick={() => history.push(`${match.url}/scale/${id}/edit`)}>
                <Icon name='pencil' />
              </Button>
              <Button 
                style={{ background: 'none' }} icon 
                onClick={() => handleDelete(id)}
              >
                <Icon color="red" name="trash" />
              </Button>
            </div>
          ),
          markings: <table style={{ width: '100%', tableLayout: 'fixed' }}>
            <tbody>
              <tr>
                <td style={{ width: '5em', padding: '0' }}>Major: </td>
                <td style={{ padding: '0' }}>{scale.major_markings.map(marking => precisionRound(marking*multiplier, 5)).join(', ')}</td>
              </tr>
              <tr>
                <td style={{ width: '5em', padding: '0' }}>Minor: </td>
                <td style={{ padding: '0' }}>{scale.minor_markings.map(marking => precisionRound(marking*multiplier, 5)).join(', ')}</td>
              </tr>
              <tr>
                <td style={{ width: '5em', padding: '0' }}>Text: </td>
                <td style={{ padding: '0' }}>{scale.text_markings.map(marking => precisionRound(marking*multiplier, 5)).join(', ')}</td>
              </tr>
              <tr>
                <td style={{ width: '5em', padding: '0' }}>Callout: </td>
                <td style={{ padding: '0' }}>{scale.callout_markings.map(marking => precisionRound(marking*multiplier, 5)).join(', ')}</td>
              </tr>
            </tbody>
          </table>,
          colorbands: colorbands.length > 0 && 
            <table style={{ width: '100%', tableLayout: 'fixed' }}>
              <tbody>
                { colorbands.map((band, i) => 
                    <tr key={i}>
                      <td style={{ padding: '0' }}>{band.color}: {band.from} to {band.to} {unit.name}</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          }
        ))}
      />}
      { (user.isDialPrinting || user.isSales) && !isArchived && 
        <Button style={{ marginTop: '32px' }} secondary icon onClick={() => history.push(`${match.url}/scale/create`)}>
          <Icon name='plus' />
          Add scale
        </Button>
      }
    </div>
  );
}

const GaugeLineSwitches = ({ switches }) => {
  if (switches == null || switches.length === 0) return null;
  return (
    <DataTable
      tableProps={{ compact: 'very', basic: 'very' }}
      columns={[
        { key: 'id', value: '', textAlign: 'right' },
        { key: 'setpoint', value: 'Setpoint' },
        { key: 'range_to', value: 'Range' },
        { key: 'direction', value: 'Direction', },
      ]}
      rows={switches.map(({ unit, setpoint, range_to, is_ascending, ...switch_props }, i) => ({
        key: i+1,
        id: i+1,
        setpoint: `${setpoint} ${unit.name}`,
        range_to: `${range_to} ${unit.name}`,
        direction: is_ascending ? 'Ascending' : 'Descending',
        ...switch_props,
      }))}
    />
  );
}

const GaugeLineGauges = ({ className, gauges, id_gauge_line, isArchived, refetchQuery }) => {
  const user = useContext(UserContext);
  const [createDialogOpen, setCreateDialogOpen] = useState(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(null);
  const [m_gauge_delete] = useMutation(QUERIES.m_gauge_delete);

  const handleDelete = async (id) => {
    if (window.confirm('Confirm deletion?'))
      await m_gauge_delete({ variables: { id }});
    refetchQuery();
  }

  return (
    <div className={className}>
      <DialogGaugeCreate open={createDialogOpen} setOpen={setCreateDialogOpen} onComplete={refetchQuery} />
      <DialogGaugeUpdate open={updateDialogOpen} setOpen={setUpdateDialogOpen} onComplete={refetchQuery} />
      <h2>Gauges</h2>
      {gauges != null && gauges.length > 0 && 
        <DataTable
          tableProps={{ striped: true, compact: 'very', basic: 'very' }}
          columns={[
            { key: 'id', value: '' },
            { key: 'tag_number', value: 'Tag number', },
            { key: 'assembled_at', value: 'Assembled', },
            { key: 'angle_checked_at', value: 'Angle Checked', },
            { key: 'dial_calibrated_at', value: 'Calibrated', },
            { key: 'dial_crosschecked_at', value: 'Dial Cross', },
            { key: 'switch_crosschecked_at', value: 'Switch Cross', },
            { key: 'finished_at', value: 'Finished', },
            { key: 'checked_at', value: 'Checked', },
            { key: 'packed_at', value: 'Packed', },
            { key: 'line_actions', value: '' },
          ]}
          rows={gauges.map(gauge => ({
            key: gauge.id,
            id: gauge.id,
            tag_number: fragmentNewlines(gauge.tag_number),
            assembled_at: gauge.assembled_at && dayjs(gauge.assembled_at).format('DD-MM-YYYY hh:mmA'),
            angle_checked_at: gauge.angle_checked_at && dayjs(gauge.angle_checked_at).format('DD-MM-YYYY hh:mmA'),
            dial_calibrated_at: gauge.dial_calibrations.length > 0 && dayjs(gauge.dial_calibrations[0].calibrated_at).format('DD-MM-YYYY hh:mmA'),
            dial_crosschecked_at: gauge.dial_crosschecks.length > 0 && dayjs(gauge.dial_crosschecks[0].checked_at).format('DD-MM-YYYY hh:mmA'),
            switch_crosschecked_at: gauge.switch_crosschecks.length > 0 && dayjs(gauge.switch_crosschecks[0].checked_at).format('DD-MM-YYYY hh:mmA'),
            finished_at: gauge.finished_at && dayjs(gauge.finished_at).format('DD-MM-YYYY hh:mmA'),
            checked_at: gauge.checked_at && dayjs(gauge.checked_at).format('DD-MM-YYYY hh:mmA'),
            packed_at: gauge.packed_at && dayjs(gauge.packed_at).format('DD-MM-YYYY hh:mmA'),
            line_actions: user.isSales && !isArchived && (
              <div>
                <Button style={{ background: 'none' }} icon onClick={() => setUpdateDialogOpen({ id: gauge.id })}>
                  <Icon name='pencil' />
                </Button>
                <Button style={{ background: 'none' }} icon onClick={() => handleDelete(gauge.id)} >
                  <Icon color="red" name="trash" />
                </Button>
              </div>
            )
          }))}
        />
      }
      { user.isSales && !isArchived && 
        <Button secondary icon onClick={() => setCreateDialogOpen({ id_gauge_line })}>
          <Icon name='edit' />
          Add gauges
        </Button>
      }
    </div>
  );
}


const GaugeLineDetail = ({ match, history }) => {
  const user = useContext(UserContext);
  const classes = useStyles();
  const { data, error, refetch } = useQuery(QUERIES.q_detail_gauge_line, { variables: { id: match.params.id_line }});
  const [m_delete] = useMutation(QUERIES.m_gauge_line_delete, { variables: { id: match.params.id_line }});

  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`
  const line = data.gauge_line;

  const isArchived = line.invoice && !['Sales', 'Production'].includes(line.invoice.status);

  return (
    <div>
      <div className={classes.flexContainer}>
        <div className={classes.details}>
          <Grid celled="internally" padded>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as="h2">Gauge Line: {line.sales_order.id}-{line.line}</Header>
              <PropertiesTable
                tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                keyWidth={4}
                items={[
                  { key: 'Order #', value: <Link to={`/documents/sales_order/${line.sales_order.id}`}>{line.sales_order.id}</Link> },
                  { key: 'Line #', value: line.line },
                  line.invoice && { key: 'Invoice #', value: <Link to={`/documents/invoice/${line.invoice.id}`}>{line.invoice.id}</Link> },
                  line.company_documents && { key: 'Document customer', value: line.company_documents.name },
                  line.company_label && { key: 'Label customer', value: line.company_label.name },
                  line.end_customer_po && { key: 'End customer PO #', value: line.end_customer_po },
                  { key: 'Model code', value: line.model_code },
                  line.customer_code && { key: 'Customer code', value: line.customer_code },
                  (user.isSales || user.isAccounts || user.isOwner) && { key: 'Price', value: `${line.sales_order.currency.symbol} ${line.price}` },
                  { key: 'Quantity', value: line.quantity },
                ]}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <PropertiesTable
                tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                keyWidth={4}
                items={[
                  { key: 'Max pressure (bar)', value: line.max_pressure },
                  { key: 'Max temperature (C)', value: line.max_temperature },
                  { key: 'Accuracy (%)', value: line.max_accuracy },
                  { key: 'Process connection', value: line.process_connection },
                  line.connection_adapter != null && { key: 'Adapter', value: line.connection_adapter },
                  line.article_number && { key: 'Article number', value: line.article_number },
                  { key: 'Mounting', value: line.mounting },
                  line.bracket && { key: 'Bracket', value: line.bracket },
                  line.notes && { key: 'Notes', value: line.notes },
                  line.dial && line.dial.dial_template && { key: 'Dial template', value: line.dial.dial_template.notes },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <GaugeLineScales isArchived={isArchived} scales={line.scales} id_gauge_line={line.id} refetchQuery={refetch} />
              <GaugeLineSwitches isArchived={isArchived} switches={line.switches} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </div>
        <div className={classes.actions}>
          <TitledSegment title='Actions' >
            { user.isSales && !isArchived && 
              <button onClick={() => history.push(`${match.url}/edit`)}>
                <Icon name='edit' />
                Edit
              </button> 
            }
            { user.isSales && !isArchived && 
              <button onClick={() => window.confirm('Confirm deletion?') && m_delete() && history.replace(`../`)}>
                <Icon color="red" name="trash" />
                Delete
              </button>
            }
          </TitledSegment>
          <TitledSegment title='Documents'>
            <button onClick={() => window.open(`/pdf/gauge-lines/${line.id}/calibration-certificates`)}><Icon name="file pdf" />Calibration Certificates</button>
            <button onClick={() => window.open(`/pdf/gauge-lines/${line.id}/test-certificate`)}><Icon name="file pdf" />Test Certificates</button>
            <button onClick={() => window.open(`/pdf/gauge-lines/${line.id}/gauge-labels`)}><Icon name="file pdf" />Gauge Labels</button>
            <button onClick={() => window.open(`/pdf/gauge-lines/${line.id}/inner-box-labels`)}><Icon name="file pdf" />Inner Box Labels</button>
            <button onClick={() => window.open(`/pdf/gauge-lines/${line.id}/working-labels`)}><Icon name="file pdf" />Working Labels</button>
          </TitledSegment>
        </div>
      </div>
      <GaugeLineGauges className={classes.detailItem} gauges={line.gauges} isArchived={isArchived} id_gauge_line={line.id} refetchQuery={refetch} />
    </div>
  );
}

export default GaugeLineDetail;