import { useMutation } from "@apollo/client";
import { useContext, useState } from "react";
import { Button, Form, List, Message, Modal, TextArea } from "semantic-ui-react";
import { UserContext } from "src/routes";
import * as QUERIES from "./queries";

const DialogScanner = ({ open, setOpen, onComplete }) => {
  const user = useContext(UserContext);
  const [scannedList, setScannedList] = useState([]);
  const [scanValue, setScanValue] = useState('');
  const [error, setError] = useState(null);
  const [m_gauge_line_gauge_packing_scan] = useMutation(QUERIES.m_gauge_line_gauge_packing_scan);
  
  const handleReset = () => { 
    setOpen(null); 
  }
  const handleChange = async (e, { value }) => {
    setScanValue(value);
    if (value.endsWith('\n')) {
      try {
        const { data } = await m_gauge_line_gauge_packing_scan({ variables: { id_gauge: value.trim(), id_employee: user.id, id_outer_box: open.id } });
        onComplete?.(data);
        setScannedList((prev) => [...prev, value]);
        setError(null);
      } catch ({ errors }) {
        setError('No such gauge assigned to this outer box');
      }
      setScanValue('');
    }
  }

  return (
    <Modal dimmer="blurring" open={open!=null} onClose={handleReset} size="mini">
      <Modal.Header>Scan gauges for box #{open?.box_number}</Modal.Header>
      <Modal.Content>
        <Form>
          <TextArea label='Scan' value={scanValue} onChange={handleChange} rows={10} />
        </Form>
        { error && <Message negative content={error} /> }
        <List>
          {scannedList.map(gauge => 
            <List.Item key={gauge}>
              <List.Content>{gauge}</List.Content>
            </List.Item>
          )}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleReset}>Close</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DialogScanner;