import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import SemanticField from "src/misc/SemanticField";
import * as yup from "yup";
import * as QUERIES from "./queries";

const validationSchema = yup.object().shape({
  id_sales_order: yup.string().required(),
  line: yup.number().integer().nullable(),
});

const DialogAddLines = ({ open, setOpen, onComplete }) => {
  const [m_invoice_add_sales_order_line] = useMutation(QUERIES.m_invoice_add_sales_order_line);
  
  const handleReset = () => { 
    setOpen(null); 
  }
  const handleSubmit = (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    const { data } = m_invoice_add_sales_order_line({ variables: { id_invoice: open.id_invoice, ...fields }});
    setOpen(null);
    onComplete && onComplete(data);
  }
  const initialValues = validationSchema.default();

  return (
    <Modal dimmer="blurring" open={open!=null} onClose={handleReset} size="mini">
      <Modal.Header>Add sales order lines</Modal.Header>
      <Modal.Content>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} onReset={handleReset} validationSchema={validationSchema}>
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="id_sales_order" component={Form.Input} label="Sales order" />
              <SemanticField name="line" component={Form.Input} label="Line # (keep blank to add whole order)" />
              <Button basic color='red' type="reset"><Icon name='remove' />Discard</Button>
              <Button color='green' type="submit"><Icon name='checkmark' />Add</Button>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}

export default DialogAddLines;