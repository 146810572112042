import gql from 'graphql-tag.macro';

const f_unit_scalar_details = gql`
  fragment UnitMeasureDetailsFragment on UnitMeasure {
    id
    name
    description
    mapping
    dimension
    id_unit_certificate
    notes
  }
`;

export const m_create = gql`
  mutation unit_measure_create ($input: UnitMeasureCreateInput!) {
    unit_measure_create (input: $input) {
      id
    },
  }
`;

  export const m_delete = gql`
  mutation unit_measure_delete ($id: ID!) {
    unit_measure_delete (id: $id) {
      id
    }
  }
`;

export const m_update = gql`
  mutation unit_measure_update ($id: ID!, $input: UnitMeasureUpdateInput!) {
    unit_measure_update (id: $id, input: $input) {
      id
    },
  }
`;

export const q_update = gql`
  query unit_measure_update ($id: ID!) {
    unit_measure (id: $id) {
      ...UnitMeasureDetailsFragment
    },
  }
  ${f_unit_scalar_details}
`;

export const q_form = gql`
  query unit_measure_form {
    unit_measures (limit: 10000) {
      id
      name
      notes
    }
  }
`;

export const q_list = gql`
  query unit_measures ($limit: Int, $offset: Int, $filter: UnitMeasureFilterInput, $sort: [UnitMeasureSortInput!]) {
    list: unit_measures (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      ...UnitMeasureDetailsFragment
      unit_certificate {
        name
        notes
      }
    }
  }
  ${f_unit_scalar_details}
`;