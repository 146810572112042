import gql from 'graphql-tag.macro';

export const q_dashboard_dispatch = gql`
  query dashboard_dispatch ($date_from: Date!,$date_to: Date!) {
    invoices (
      filter: {status: "Production", date_from: $date_from, date_to: $date_to}
      sort: [{date: ASC}, {id: DESC}]
    ) {
      id
      invoice_date
      id_sales_orders
      customer_billing {
        name
      }
      address_billing {
        country
      }
      packing_status {
        qty_assembled
        qty_angle_checked
        qty_calibrated
        qty_dial_crosschecked
        qty_switch_crosschecked
        qty_finished
        qty_checked
        qty_packed
        qty_total
      }
    }
  }
`;