import gql from 'graphql-tag.macro';

export const f_scalar_details = gql`
  fragment dial_template_line_scalar_details_fragment on DialTemplateLine {
    id
    x1
    y1
    x2
    y2
    strokewidth
    strokecolor
  }
`;

export const m_delete = gql`
mutation dial_template_line_delete ($id: ID!){
  dial_template_line_delete (id: $id) {
    id
  }
}`;

export const m_create = gql`
mutation dial_template_line_create ($id_dial_template: ID!, $input: DialTemplateLineInput!){
  dial_template_line_create (id_dial_template: $id_dial_template, input: $input) {
    id
  },
}`;

export const m_update = gql`
mutation dial_template_line_update ($id: ID!, $input: DialTemplateLineInput!){
  dial_template_line_update (id: $id, input: $input) { 
    id
  },
}`;

export const q_update = gql`
query update_dial_template_line ($id: ID!) {
  dial_template_line (id: $id) {
    ...dial_template_line_scalar_details_fragment
  },
  ${f_scalar_details},
}`;