import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const continentOptions = [
  { key: "Africa", value: `Africa`, text: `Africa` },
  { key: "Antarctica", value: `Antarctica`, text: `Antarctica` },
  { key: "Asia", value: `Asia`, text: `Asia` },
  { key: "Australia", value: `Australia`, text: `Australia` },
  { key: "Europe", value: `Europe`, text: `Europe` },
  { key: "North America", value: `North America`, text: `North America` },
  { key: "South America", value: `South America`, text: `South America` },
]

const validationSchema = yup.object().shape({
  street: yup.string().required(),
  city: yup.string().nullable(),
  district: yup.string().nullable(),
  state: yup.string().nullable(),
  zipcode: yup.string().nullable(),
  country: yup.string().required(),
  continent: yup.string().required(),
});

const AddressForm = ({ history, match }) => {
  const isUpdateForm = match.params.id_address != null;
  const [updateMutation] = useMutation(QUERIES.m_customer_address_update);
  const [createMutation] = useMutation(QUERIES.m_customer_address_create);
  const updateQuery = useQuery(QUERIES.q_customer_address_update, { variables: { id: match.params.id_address }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    Object.keys(validationSchema.fields).forEach(field => {
      if (validationSchema.fields[field].type === 'string')                       fields[field] = fields[field]?.trim();
      if (validationSchema.fields[field].spec.nullable && fields[field] === '')   fields[field] = null;
    });
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id_address, input: fields }})
      : await createMutation({ variables: { input: { ...fields, id_customer: match.params.id } } });
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.customer_address ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Insert'} Address`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="street" component={Form.TextArea} label="Street" />
              <SemanticField name="city" component={Form.Input} label="City" />
              <SemanticField name="district" component={Form.Input} label="District" />
              <SemanticField name="state" component={Form.Input} label="State" />
              <SemanticField name="zipcode" component={Form.Input} label="Zipcode" />
              <SemanticField name="country" component={Form.Input} label="Country" />
              <SemanticField name="continent" fluid selection label="Continent" component={Form.Dropdown} options={continentOptions} />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default AddressForm;