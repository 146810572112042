import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Form from './Form';
import List from './List';

const ProductionScalesRoute = () => {
  let match = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${match.url}/`} component={List} />
        <Route exact path={`${match.url}/create`} component={Form} />
        <Route exact path={`${match.url}/:id/edit`} component={Form} />
      </Switch>
    </>
  );
}

export default ProductionScalesRoute;