import { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'src/routes/index.jsx';
import logo from 'src/static/images/logo_inverted.png';

// Markup and styling for dropdown nav copy pasta from https://css-tricks.com/solved-with-css-dropdown-menus/
const useStyles = createUseStyles({
  nav: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    top: 0,
    left: 0,
    background: 'hsl(0,0%,20%)',
    '& img': { height: '35px', float: 'left', margin: '6px 20px 5px 20px' },
    '& a': { textDecoration: 'none' },
    '& ul': {
      listStyle: 'none',
      margin: 0,
      paddingLeft: 0,
      '& li': {
        '&:hover > ul, &:focus-within > ul, & ul:hover, & ul:focus': {
          visibility: 'visible',
          display: 'block',
        },
        '& ul': {
          background: 'hsl(0,0%,15%)',
          visibility: 'hidden',
          minWidth: '15rem',
          position: 'absolute',
          transition: 'all 0.5s ease',
          marginTop: '1rem',
          left: 0,
          display: 'none',
          '& li': {
            clear: 'both',
            width: '100%',
          },
        },
      },
    },
    '& li': {
      color: '#fff',
      background: 'hsl(0,0%,20%)',
      display: 'block',
      float: 'left',
      padding: '1rem',
      position: 'relative',
      textDecoration: 'none',
      transitionDuration: '0.5s',
      '& a': { color: '#fff' },
      '&:hover, &:focus-within': {
          background: 'hsl(0,0%,30%)',
          cursor: 'pointer',
      },
      '&:focus-within a': { outline: 'none' },
    },
  }
});

const NavBar = () => {
  const user = useContext(UserContext);
  const history = useHistory();
  const classes = useStyles();

  
  const routes = [
    {
      title: "Sales", 
      routes: [
        { title: "Customer", url: "/masters/customer", hidden: !user.isSales },
        { title: "Inventory", url: "/masters/inventory", hidden: !user.isSales },
        { title: "Sales Order", url: "/documents/sales_order", hidden: !user.isSales && !user.isProduction },
        { title: "Invoice", url: "/documents/invoice", hidden: !user.isSales && !user.isProduction },
      ],
    },
    {
      title: "Purchase",
      routes: [
        { title: "Vendor", url: "/masters/vendors", hidden: !user.isPurchase },
        { title: "Material", url: "/masters/material", hidden: !user.isPurchase },
        { title: "Material group", url: "/masters/material_group", hidden: !user.isPurchase },
        { title: "Service", url: "/masters/service", hidden: !user.isPurchase },
        { title: "Service group", url: "/masters/service_group", hidden: !user.isPurchase },
        { title: "Purchase Order", url: "/documents/purchase_order", hidden: !user.isPurchase },
        { title: "Requisition", url: "/documents/requisition" },
        { title: "Purchase order lines", url: "/reports/purchase_order_lines", hidden: !user.isPurchase },
      ],
    },
    {
      title: "Inventory",
      routes: [
        { title: "Warehouse", url: "/masters/warehouse", hidden: !user.isStores },
        { title: "Material movement", url: "/documents/material_movement", hidden: !user.isStores },
        { title: "Stock statement", url: "/reports/stock_statement", hidden: !user.isStores },
        { title: "Group movement", url: "/reports/group_movement", hidden: !user.isStores },
      ],
    },
    {
      title: "Production",
      routes: [
        { title: "Box", url: "/masters/box", hidden: !user.isProduction && !user.isSales },
        { title: "Dial Template", url: "/masters/dial_template", hidden: !user.isDialPrinting },
        { title: "Scale", url: "/masters/scale", hidden: !user.isProduction && !user.isSales },
        { title: "Series", url: "/masters/series", hidden: !user.isProduction && !user.isSales },
        { title: "Unit", url: "/masters/unit", hidden: !user.isProduction && !user.isSales },
      ],
    },
    {
      title: "Apps",
      routes: [
        { title: "Scanner", url: "/apps/scanner", hidden: !user.isProduction },
        { title: "Dispatch", url: "/apps/dispatch", hidden: !user.isDispatch },
      ],
    }
  ];

  const handleLogout = (event) => {
    try {
      fetch('/api/logout', {
        method: 'POST',
      }).then(() => {
        history.push('/login');
        localStorage.removeItem('hirlekarprecision.erp.webapp.user');
      });
    } catch (error) {
      console.error(`Error: ${error}`);  
    }
  }

  return (
    <>
      <nav className={classes.nav} role="navigation">
        <ul>
          <img alt="Hirlekar Precision logo" src={logo} />
          {
            routes.filter(route => route.routes.some(r => r.hidden !== true)).map(route => (
              <li key={route.title}>{route.title}
                <ul aria-label="submenu">
                  { route.routes.filter(r => r.hidden !== true).map(r => (
                    <li key={r.title} onClick={() => history.push(r.url)}>{r.title}</li>
                  ))}
                </ul>
              </li>
            ))
          }
          <li style={{ float: 'right' }}>{user?.first_name} {user?.last_name}
            <ul aria-label="submenu">
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default NavBar;