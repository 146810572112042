import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  website: yup.string().nullable(),
  gstin: yup.string().nullable(),
  tin: yup.string().nullable(),
  email: yup.string().nullable(),
  phone_number: yup.string().nullable(),
  label_name: yup.string().required(),
  label_website: yup.string().required(),
  id_label_template: yup.number().required().default(0),
  document_name: yup.string().nullable(),
});

const CustomerForm = ({ history, match }) => {
  const isUpdateForm = match.params.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    Object.keys(validationSchema.fields).forEach(field => {
      if (validationSchema.fields[field].type === 'string')                       fields[field] = fields[field]?.trim();
      if (validationSchema.fields[field].spec.nullable && fields[field] === '')   fields[field] = null;
    });
    if (isUpdateForm) {
      await updateMutation({ variables: { id: match.params.id, input: fields }});
      history.go(-1);
    } else {
      const { data } = await createMutation({ variables: { input: fields } });
      history.push(`./${data.customer_create.id}`);
    }
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.customer ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Customer`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="name" component={Form.Input} label="Name" />
              <SemanticField name="website" component={Form.Input} label="Website" />
              <SemanticField name="gstin" component={Form.Input} label="GSTIN (under Indian GST)" />
              <SemanticField name="tin" component={Form.Input} label="TIN (if not under GST)" />
              <SemanticField name="email" component={Form.Input} label="Email" />
              <SemanticField name="phone_number" component={Form.Input} label="Phone number" />
              <SemanticField name="label_name" component={Form.Input} label="Label name" />
              <SemanticField name="label_website" component={Form.Input} label="Label website" />
              <SemanticField name="id_label_template" component={Form.Input} label="Label template #" type="number" step="1" />
              <SemanticField name="document_name" component={Form.Input} label="Document name" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default CustomerForm;