import { useMutation, useQuery } from "@apollo/client";
import { Fragment, useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { Button, Dropdown, Grid, Header, Icon, Tab, Table } from "semantic-ui-react";
import DelayedLoader from "src/misc/DelayedLoader.jsx";
import PropertiesTable from "src/misc/PropertiesTable.jsx";
import { UserContext } from "src/routes";
import DialogAddOuterBox from './DialogAddOuterBox';
import DialogCreateInnerBoxes from './DialogCreateInnerBoxes';
import DialogScanner from './DialogScanner';
import * as QUERIES from "./queries.js";
import dayjs from "dayjs";
import PageHeader from "src/misc/PageHeader";

const useStyles = createUseStyles({
  container: {
    padding: '1rem'
  },
  lineRow: {
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 1rem 1rem 1rem',
    '& h2': {
      marginBottom: '0 !important',
    },
  },
  deleteButton: {
    color: 'red !important',
    boxShadow: 'none !important',
    background: 'none !important',
    '&:hover': {
      color: 'maroon !important',
    },
  },
});

const DispatchDetails = ({ match }) => {
  const user = useContext(UserContext);
  const classes = useStyles();
  const [addOuterBoxDialogOpen, setAddOuterBoxDialogOpen] = useState(null);
  const [createInnerBoxesDialogOpen, setCreateInnerBoxesDialogOpen] = useState(null);
  const [scannerDialogOpen, setScannerDialogOpen] = useState(null);

  const { data, error, refetch } = useQuery(QUERIES.q_detail, { variables: { id: match.params.id }});
  const [m_status_update] = useMutation(QUERIES.m_status_update);
  const [m_gauge_line_weight] = useMutation(QUERIES.m_gauge_line_weight);
  const [m_outer_box_delete] = useMutation(QUERIES.m_outer_box_delete);
  const [m_inner_box_delete] = useMutation(QUERIES.m_inner_box_delete);
  const [m_inventory_sale_line_packing_pack_outer_box] = useMutation(QUERIES.m_inventory_sale_line_packing_pack_outer_box);

  if (data == null) return <DelayedLoader />;
  if (error) return `Error!: ${JSON.stringify(error, null, 2)}`
  
  const invoice = data.invoice;
  let isArchived = false, invoiceLines = [];
  if (invoice) {
    isArchived = !['Sales', 'Production'].includes(invoice.status);
    invoiceLines.push(...invoice.gauge_lines.map(line => ({
      ...line,
      ...line.production_status,
      rows: line.gauges.length,
      line_type: 'Gauge',
    })));
    invoiceLines.push(...invoice.inventory_sale_lines.map(line => {
      const grouped = [];
      line.packings.forEach(packing => {
        let group = grouped.find(group => group.box_number === packing.inner_box.outer_box.box_number);
        if (group == null) {
          group = { box_number: packing.inner_box.outer_box.box_number, quantity: 0, packed: false };
          grouped.push(group);
        }
        if (packing.packed_at)  group.packed = true;
        group.quantity += packing.quantity;
      });
      return {
        ...line,
        ...line.production_status,
        model_code: line.inventory.model_code,
        packings: grouped,
        rows: grouped.length,
        line_type: 'Inventory',
      }
    }));
    invoiceLines.sort((a, b) => (a.id_sales_order > b.id_sales_order) || (a.line > b.line));
  }

  async function handleMarkDispatched() {
    if (window.confirm('Confirm dispatch?')) {
      await m_status_update({ variables: { id: invoice.id, status: 'Dispatched', id_user: user.id  } });
      refetch();
    }
  }

  async function handleEditGaugeWeight(lineId, oldWeight) {
    const weight = window.prompt('Enter new weight:', oldWeight)
    if (weight != null) {
      await m_gauge_line_weight({ variables: { id: lineId, weight: parseFloat(weight) } });
      refetch();
    }
  }

  async function handleDeleteOuterBox(id) {
    if (window.confirm('Confirm delete outer box and all inners?')) {
      try {
        await m_outer_box_delete({ variables: { id } });
        refetch();
      } catch (errors) {
        window.alert(`Error in deleting - ${JSON.stringify(errors)}`);
      }
    }
  }

  async function handleDeleteInnerBox(id) {
    if (window.confirm('Confirm delete inner box and unpack items?')) {
      try {
        await m_inner_box_delete({ variables: { id } });
        refetch();
      } catch (errors) {
        window.alert(`Error in deleting - ${JSON.stringify(errors)}`);
      }
    }
  }

  async function handlePackInventory(id_outer_box) {
    if (window.confirm('Confirm pack inventory?')) {
      try {
        await m_inventory_sale_line_packing_pack_outer_box({ variables: { id_outer_box, id_packed_by: user.id } });
        refetch();
      } catch (errors) {
        window.alert(`Error in packing inventory - ${JSON.stringify(errors)}`);
      }
    }
  }

  return (
    <>
      <DialogScanner open={scannerDialogOpen} setOpen={setScannerDialogOpen} onComplete={refetch} />
      <DialogAddOuterBox id_invoice={invoice?.id} open={addOuterBoxDialogOpen} setOpen={setAddOuterBoxDialogOpen} onComplete={refetch} />
      <DialogCreateInnerBoxes id_invoice={invoice?.id} open={createInnerBoxesDialogOpen} setOpen={setCreateInnerBoxesDialogOpen} onComplete={refetch}
        gauge_lines={invoice.gauge_lines.filter(line => line.quantity - line.production_status.qty_assigned_box > 0)}
        inventory_lines={invoice.inventory_sale_lines.filter(line => line.quantity - line.production_status.qty_assigned_box > 0)}
      />
      <PageHeader
        title={`Dispatch: ${invoice.id}`}
        subtitle={`${dayjs(invoice.invoice_date).format('DD MMM YY')}\nStatus - ${invoice.status}`}
        rightAction={[
          <Button onClick={() => handleMarkDispatched(invoice.id)}>Mark dispatched</Button>,
        ]}
      />
      <div className={classes.container}>
        <div className={classes.details}>
          <Grid celled='internally'>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as="h3">Ship To: {invoice.customer_shipping.name}</Header>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'Street', value: invoice.address_shipping.street },
                    { key: 'City', value: invoice.address_shipping.city },
                    { key: 'Zipcode', value: invoice.address_shipping.zipcode },
                    { key: 'District', value: invoice.address_shipping.district },
                    { key: 'State', value: invoice.address_shipping.state },
                    { key: 'Country', value: invoice.address_shipping.country },
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as="h3">Freight</Header>
                <PropertiesTable
                  tableProps={{ basic: 'very', celled: true, compact: 'very' }}
                  items={[
                    { key: 'Freight carrier', value: invoice.freight_carrier },
                    { key: 'Freight mode', value: invoice.freight_mode },
                    { key: 'Freight terms', value: invoice.freight_terms },
                    { key: 'Airway Bill #', value: invoice.airwaybill_number },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Tab
          panes={[
            {
              menuItem: 'Order Lines',
              render: () => (
                <Tab.Pane>
                  <Table celled selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width="1">OA #</Table.HeaderCell>
                        <Table.HeaderCell width="5">Model code</Table.HeaderCell>
                        <Table.HeaderCell width="1" textAlign="right">Weight /pc</Table.HeaderCell>
                        <Table.HeaderCell width="1" textAlign="right">Item #</Table.HeaderCell>
                        <Table.HeaderCell width="2" textAlign="right">Assigned box</Table.HeaderCell>
                        <Table.HeaderCell width="2" textAlign="right">Outer box</Table.HeaderCell>
                        <Table.HeaderCell width="2" textAlign="right">Packed</Table.HeaderCell>
                        <Table.HeaderCell width="2" textAlign="right">Quantity</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      { invoiceLines.map(line => (
                        <Fragment key={line.id}>
                          <Table.Row verticalAlign="top" key={line.id} className={classes.lineRow}>
                            <Table.Cell rowSpan={line.rows+1}>{line.id_sales_order}-{line.line}</Table.Cell>
                            <Table.Cell rowSpan={line.rows+1}>{line.model_code}</Table.Cell>
                            <Table.Cell rowSpan={line.rows+1} textAlign="right">{line.weight} kgs</Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell textAlign="right">{line.qty_assigned_box} pcs</Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell textAlign="right">{line.qty_packed} pcs</Table.Cell>
                            <Table.Cell rowSpan={line.rows+1} textAlign="right">{line.quantity} pcs</Table.Cell>
                            <Table.Cell rowSpan={line.rows+1}>
                              { line.line_type === 'Gauge' && !isArchived &&
                                <Dropdown icon="ellipsis vertical" direction="left">
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleEditGaugeWeight(line.id, line.weight)}>Edit weight</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              }
                            </Table.Cell>
                          </Table.Row>
                          { line.gauges?.map(gauge => (
                            <Table.Row key={gauge.id}>
                              <Table.Cell textAlign="right">{gauge.line_item}</Table.Cell>
                              <Table.Cell textAlign="right">{gauge.inner_box?.id_box ?? '❌'}</Table.Cell>
                              <Table.Cell textAlign="right">{gauge.inner_box?.outer_box?.box_number ?? '❌'}</Table.Cell>
                              <Table.Cell textAlign="right">{gauge.packed_at ? '✅' : '❌'}</Table.Cell>
                            </Table.Row>
                          ))}
                          { line.packings?.map(packing => (
                            <Table.Row key={packing.box_number}>
                              <Table.Cell></Table.Cell>
                              <Table.Cell textAlign="right">{packing.quantity} pcs</Table.Cell>
                              <Table.Cell textAlign="right">{packing.box_number}</Table.Cell> 
                              <Table.Cell textAlign="right">{packing.packed ? '✅' : '❌'}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Fragment>
                      ))}
                    </Table.Body>
                  </Table>
                </Tab.Pane>
              ),
            },
            {
              menuItem: 'Outer Boxes',
              render: () => (
                <Tab.Pane>
                  <Table celled selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width="6">Outer box</Table.HeaderCell>
                        <Table.HeaderCell width="2">Inner box</Table.HeaderCell>
                        <Table.HeaderCell width="2">Line item</Table.HeaderCell>
                        <Table.HeaderCell width="2">Packed</Table.HeaderCell>
                        <Table.HeaderCell width="2" textAlign="right">Net weight</Table.HeaderCell>
                        <Table.HeaderCell width="2" textAlign="right">Gross weight</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      { invoice.outer_boxes.map(outer => (
                        <Fragment key={outer.id}>
                          <Table.Row key={outer.id} className={classes.lineRow}>
                            <Table.Cell rowSpan={outer.inner_boxes.length+1}>#{outer.box_number}<br/>Box - {outer.box.id}<br/>Box weight - {outer.box.weight} kgs<br/>Tare weight - {outer.tare_weight} kgs</Table.Cell>
                            <Table.Cell>{outer.inner_boxes.length} inner boxes</Table.Cell>
                            <Table.Cell>{outer.packing_details.quantity} pcs</Table.Cell>
                            <Table.Cell>{outer.packing_details.qty_packed} pcs</Table.Cell>
                            <Table.Cell textAlign="right">{outer.packing_details.net_weight} kgs</Table.Cell>
                            <Table.Cell textAlign="right">{outer.packing_details.gross_weight} kgs</Table.Cell>
                            <Table.Cell>
                              { !isArchived && 
                                <Dropdown icon="ellipsis vertical" direction="left">
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setCreateInnerBoxesDialogOpen(outer)}>Assign inner boxes</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setScannerDialogOpen(outer)}>Scan</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handlePackInventory(outer.id)}>Pack inventory</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setAddOuterBoxDialogOpen(outer)}>Edit</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDeleteOuterBox(outer.id)}>Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown> 
                              }
                            </Table.Cell>
                          </Table.Row>
                          { outer.inner_boxes.map(inner => (
                            <Table.Row key={inner.id}>
                              <Table.Cell>{inner.id_box}</Table.Cell>
                              <Table.Cell style={{ whiteSpace: "pre" }}>{[inner.gauges.map(gauge => gauge.id).join('\n'), inner.inventories.map(inv => `${inv.id_inventory_sale_line} - ${inv.quantity} pcs`)].join('\n')}</Table.Cell>
                              <Table.Cell style={{ whiteSpace: "pre" }}>{[inner.gauges.map(gauge => gauge.packed_at ? '✅' : '❌').join('\n'), inner.inventories.map(inv => inv.packed_at ? '✅' : '❌')].join('\n')}</Table.Cell>
                              <Table.Cell textAlign="right">{inner.packing_details.net_weight} kgs</Table.Cell>
                              <Table.Cell textAlign="right">{inner.packing_details.gross_weight} kgs</Table.Cell>
                              <Table.Cell>
                                { !isArchived && 
                                  <Button className={classes.deleteButton} icon circular 
                                    onClick={() => handleDeleteInnerBox(inner.id)}
                                  ><Icon name='trash' /></Button>
                                }
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Fragment>
                      ))}
                    </Table.Body>
                  </Table>
                  <Button primary onClick={() => setAddOuterBoxDialogOpen({ box_number: invoice.outer_boxes.length+1 })}>Add outer box</Button>
                </Tab.Pane>
              ),
            },
          ]}
        />
      </div>
    </>
  )
}

export default DispatchDetails;