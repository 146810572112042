import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Detail from './Detail';
import Form from './Form';
import GaugeLineDetail from './gauge_line/Detail';
import GaugeLineForm from './gauge_line/Form';
import InventoryLineDetail from './inventory_line/Detail';
import InventorySaleLineForm from './inventory_line/Form';
import List from './List';
import GaugeLineScaleForm from './gauge_line/ScaleForm';

const SalesOrderRoute = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${path}/`} component={List} />
        <Route exact path={`${path}/create`} component={Form} />
        <Route exact path={`${path}/:id`} component={Detail} />
        <Route exact path={`${path}/:id/edit`} component={Form} />

        <Route exact path={`${path}/:id/create_gauge_line`} component={GaugeLineForm} />
        <Route exact path={`${path}/:id/gauge_line/:id_line`} component={GaugeLineDetail} />
        <Route exact path={`${path}/:id/gauge_line/:id_line/edit`} component={GaugeLineForm} />
        <Route exact path={`${path}/:id/gauge_line/:id_line/scale/create`} component={GaugeLineScaleForm} />
        <Route exact path={`${path}/:id/gauge_line/:id_line/scale/:id_scale/edit`} component={GaugeLineScaleForm} />
        
        <Route exact path={`${path}/:id/create_inventory_line`} component={InventorySaleLineForm} />
        <Route exact path={`${path}/:id/inventory_line/:id_line`} component={InventoryLineDetail} />
        <Route exact path={`${path}/:id/inventory_line/:id_line/edit`} component={InventorySaleLineForm} />
      </Switch>
    </>
  );
}

export default SalesOrderRoute;