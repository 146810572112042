import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import DataTable from 'src/misc/DataTable';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const useStyles = createUseStyles({
  container: {
    '&>h2': {
      color: 'hsl(0, 0%, 40%)',
      fontWeight: 'bold',
    },
    '&>*': { margin: '32px 0' },
  }
})

const DialTemplateLineTable = ({ lines, refetchQuery }) => {
  const classes = useStyles();
  const user = useContext(UserContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [m_delete] = useMutation(QUERIES.m_delete);

  const handleDelete = (id) => {
    if (window.confirm('Confirm deletion?')) {
      m_delete({ variables: { id }}); 
      refetchQuery();
    }
  }

  return (
    <div className={classes.container}>
      <h2>Lines</h2>
      <DataTable
        tableProps={{ celled: true, basic: 'very', compact: 'very' }}
        columns={[
          { key: 'x1', value: 'Line start x (mm)' },
          { key: 'y1', value: 'Line start y (mm)' },
          { key: 'x2', value: 'Line end x (mm)' },
          { key: 'y2', value: 'Line end y (mm)' },
          { key: 'strokewidth', value: 'Strokewidth (mm)' },
          { key: 'strokecolor', value: 'Strokecolor' },
          { key: 'line_actions', value: '', width: 1, ignoreClick: true, hidden: !user.isDialPrinting },
        ]}
        rows={ lines.map(({ id, ...line }) => ({
          ...line,
          key: id,
          line_actions: (
            <div>
              <Button style={{ background: 'none' }} icon onClick={() => history.push(`${match.url}/line/${id}/edit`)}>
                <Icon name='pencil' />
              </Button>
              <Button style={{ background: 'none' }} icon onClick={() => handleDelete(id)}>
                <Icon color="red" name="trash" />
              </Button>
            </div>
          ),
        }))}
      />
      { user.isDialPrinting && 
        <Button secondary onClick={() => history.push(`${match.url}/create_line`)} icon labelPosition='left'>
          <Icon name='add' />
          New line
        </Button>
      }
    </div>
  );
}

export default DialTemplateLineTable;