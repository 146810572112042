import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Header, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import { all, isEmptyString, isNumber } from 'src/misc/validators.js';
import * as yup from 'yup';
import * as QUERIES from './queries';

const DIMENSION_OPTIONS = [
  { id: '', value: '', text: '' },
  { id: 'length', value: 'length', text: 'length' },
  { id: 'area', value: 'area', text: 'area' },
  { id: 'volume', value: 'volume', text: 'volume' },
  { id: 'mass', value: 'mass', text: 'mass' },
  { id: 'time', value: 'time', text: 'time' },
  { id: 'electric_current', value: 'electric_current', text: 'electric_current' },
  { id: 'temperature', value: 'temperature', text: 'temperature' },
  { id: 'pressure', value: 'pressure', text: 'pressure' },
  { id: 'flow', value: 'flow', text: 'flow' },
];

const validateMappingArrayFromStr = str => (str === "") || str.split('\n').map(i => i.split('\t')).map(pair => pair.length === 2 && pair.map(isNumber).reduce(all, true)).reduce(all, true);
const parseMappingArrayFromStr = str => str === "" ? [] : str.split('\n').map(i => i.split('\t').map(i => Number(i)));
const formatArrayToStr = arr => Array.isArray(arr) ? arr.map(i => i.join('\t')).join('\n') : arr;

const makeUnitOptions = ({ id, name, notes }) => ({ key: id, value: id, text: name, description: notes });

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().nullable(),
  notes: yup.string().nullable(),
  dimension: yup.string().required().default('pressure'),
  id_unit_certificate: yup.string().nullable(),
  mapping: yup.string().default('').test('mapping-pair-check', '${path} must contain list of numerical pairs', validateMappingArrayFromStr), // eslint-disable-line
});

const UnitForm = ({ history, match }) => {
  const isUpdateForm = match.params.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id }, skip: !isUpdateForm });
  const formDataQuery = useQuery(QUERIES.q_form);
  
  if (formDataQuery.loading || updateQuery?.loading) return null;
  if (formDataQuery.error)  return `Error!: ${JSON.stringify(formDataQuery.error, null, 2)}`;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;


  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    fields.mapping = parseMappingArrayFromStr(fields.mapping);
    if (isEmptyString(fields.id_unit_certificate))  fields.id_unit_certificate = null; 
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id , input: fields }})
      : await createMutation({ variables: { input: fields } });
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.unit_measure ?? validationSchema.default());
  initialValues.mapping = formatArrayToStr(initialValues.mapping);

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Unit`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField fast name="name" component={Form.Input} label="Name" />
              <SemanticField fast name="description" component={Form.Input} label="Description (appears on certificate)" />
              <SemanticField fast name="notes" component={Form.TextArea} label="Notes" />
              <SemanticField fast name="dimension" component={Form.Dropdown} fluid selection label="Dimension" options={DIMENSION_OPTIONS} />
              <SemanticField fast name="id_unit_certificate" component={Form.Dropdown} fluid selection clearable label="Certificate Unit" options={formDataQuery.data.unit_measures.map(makeUnitOptions)} />
              <Header as="h5">Mapping<br/>(bar - unit)</Header>
              <SemanticField fast name="mapping" component={Form.TextArea} rows={12}
                style={{ MozTabSize: 24, tabSize: 24 }}
                onKeyDown={(e) => {
                  if(e.keyCode === 9 || e.which === 9){
                    e.preventDefault();
                    var s = e.target.selectionStart;
                    e.target.value = e.target.value.substring(0,e.target.selectionStart) + "\t" + e.target.value.substring(e.target.selectionEnd);
                    e.target.selectionEnd = s+1; 
                  }
                }}
              />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default UnitForm;