import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Table } from 'semantic-ui-react';
import clsx from 'clsx';

const useStyles = createUseStyles({
  header: {
    verticalAlign: 'bottom',
  },
  slantedHeader: {
    height: '80px',
    width: '70px',
    '& > div': {
      transform: 'translate(-5px, 10px) rotate(-45deg)',
      textAlign: 'center',
    },
  },
});

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const interpolateColor = (minColor, maxColor, val) => {
  minColor = hexToRgb(minColor);
  maxColor = hexToRgb(maxColor);
  
  const r = minColor.r + Math.pow(val/100, 2)*(maxColor.r-minColor.r);
  const g = minColor.g + Math.pow(val/100, 2)*(maxColor.g-minColor.g);
  const b = minColor.b + Math.pow(val/100, 2)*(maxColor.b-minColor.b);
  const a = 0.85;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const DataTable = ({ tableProps, rowProps, onClick, columns, rows, handleContextMenu }) => {
  const footerTotals = new Array(columns.length).fill(0);
  for (let i=0; i<rows.length; i++)
    for (let j=0; j<columns.length; j++)
  if (columns[j].footer === 'total')   footerTotals[j] += rows[i][columns[j].key];
  
  const classes = useStyles();
  return (
    <Table {...tableProps} >
      <Table.Header>
        <Table.Row>
          {columns
            .filter(({ hidden }) => !hidden)
            .map(({ key, value, slanted, width, textAlign }) => 
              <Table.HeaderCell className={clsx(classes.header, slanted && classes.slantedHeader)}
                key={key} width={width} textAlign={textAlign}
              >
                <div>{value}</div>
              </Table.HeaderCell>
            )
          }
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {rows.map((row, i) => (
          <Table.Row key={row.key} {...rowProps} >
            {columns
              .filter(({ hidden }) => !hidden)
              .map((col) => {
                let style = { ...col.cellStyle }, title, cellValue;
                if (col.type === 'percent') {
                  const percentValue = Math.round(100*row[col.key]/row[col.maxValue])
                  const backgroundColor = interpolateColor('#ffffff', '#000000', percentValue);
                  const color = percentValue > 60 ? '#ffffff' : '#000000';
                  style = { color, backgroundColor, mixBlendMode: 'multiply' };
                  title = `${percentValue}%`;
                  cellValue = row[col.key];
                } else {
                  cellValue = row[col.key];
                }
                if (col.ignoreClick) style.cursor = 'default';
                return <Table.Cell
                  key={col.key} textAlign={col.textAlign} style={style} title={title}
                  onClick={(onClick && !col.ignoreClick) ? (() => onClick(row.key, i)) : undefined}
                  onContextMenu={handleContextMenu ? handleContextMenu(row.key) : undefined}
                >{col.format ? col.format(cellValue) : cellValue}</Table.Cell>
              })
            }
          </Table.Row>
        ))}
      </Table.Body>
      {columns.find(col => col.footer != null) && 
        <Table.Footer>
          <Table.Row>
            {columns.map((col, idx) => {
              if (col.hidden) return null;
              const cell = col.footer === 'total'
                ? (col.format ? col.format(footerTotals[idx]) : footerTotals[idx]) 
                : col.footer;
              return <Table.HeaderCell key={col.key} textAlign={col.textAlign} width={col.width} style={{ fontWeight: 900 }}>{cell}</Table.HeaderCell>
            })}
          </Table.Row>
        </Table.Footer>
      }
    </Table>
  );
}

DataTable.propTypes = {
  tableProps: PropTypes.object,
  rowProps: PropTypes.object,
  onClick: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,   // Key can be any string except for 'key'
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    textAlign: PropTypes.string,
    width: PropTypes.number,
    hidden: PropTypes.bool,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  footer: PropTypes.array,
}

export default DataTable;