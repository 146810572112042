import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const UnitList = ({ match, history }) => {
  const [m_delete] = useMutation(QUERIES.m_delete);
  const user = useContext(UserContext);
  return (
    <>
      <PageHeader title="Units" 
        leftAction={user.isProduction && <Button onClick={() => history.push(`${match.url}/create`)}>New</Button>}
      />
      <CollectionList
        query={QUERIES.q_list}
        tableColumns={[
          { key: 'name', value: 'NAME', width: '15%' },
          { key: 'description', value: 'DESCRIPTION', width: '15%' },
          { key: 'dimension', value: 'DIMENSION', width: '15%' },
          { key: 'unit_certificate', value: 'CERT UNIT', width: '15%' },
          { key: 'notes', value: 'NOTES', width: 'auto' },
          { key: 'line_actions', sortable: false, value: '', width: '8%', hidden: !user.isProduction },
        ]}
        mapDataRowToTable={unit => ({
          ...unit,
          key: unit.id,
          mapping: JSON.stringify(unit.mapping, null, 2),
          unit_certificate: unit.unit_certificate && unit.unit_certificate.name,
          line_actions: (
            <div>
              <Button onClick={() => history.push(`${match.url}/${unit.id}/edit`)} style={{ background: 'none' }} icon >
                <Icon name='pencil' />
              </Button>
              <Button style={{ background: 'none' }} icon
                onClick={async () => {
                  if (window.confirm('Confirm deletion? Cannot delete if used anywhere.')) {
                    await m_delete({ variables: { id: unit.id }});
                    history.go(0);
                  } 
                }}
              >
                <Icon color="red" name="trash" />
              </Button>
            </div>
          ),
        })}
        defaultSortOrder={[{ name: 'ASC' }, { notes: 'ASC' }]}
        defaultFilters={{ name: '' }}
        filters={[
          { key: 'name', label: 'NAME', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { };
          if (!isEmptyString(filterOptions.name))  
            queryFilters.name = filterOptions.name;
          return queryFilters;
        }}
      />
    </>
  )
};
export default UnitList;