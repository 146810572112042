import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const SeriesList = ({ match, history }) => {
  const [m_delete] = useMutation(QUERIES.m_delete);
  const user = useContext(UserContext);
  return (
    <>
      <PageHeader title="Series" 
        leftAction={user.isSales && <Button onClick={() => history.push(`${match.url}/create`)}>New</Button>}
      />
      <CollectionList
        query={QUERIES.q_list}
        tableColumns={[
          { key: 'name', value: 'NAME', width: '17%' },
          { key: 'type', value: 'TYPE', width: '15%' },
          { key: 'max_accuracy', value: 'ACCURACY (%)', width: '15%' },
          { key: 'max_pressure', value: 'PRESSURE (BAR)', width: '15%' },
          { key: 'max_temperature', value: 'TEMPERATURE (°C)', width: '15%' },
          { key: 'has_dual_dials', value: 'DUAL DIALS', width: '15%' },
          { key: 'line_actions', sortable: false, value: '', width: '8%', hidden: !user.isSales },
        ]}
        mapDataRowToTable={series => ({
          ...series,
          key: series.id,
          has_dual_dials: series.has_dual_dials ? '✔️' : '✘',
          line_actions: (
            <div>
              <Button onClick={() => history.push(`${match.url}/${series.id}/edit`)} style={{ background: 'none' }} icon >
                <Icon name='pencil' />
              </Button>
              <Button style={{ background: 'none' }} icon
                onClick={async () => {
                  if (window.confirm('Confirm deletion? Cannot delete if used anywhere.')) {
                    try {
                    await m_delete({ variables: { id: series.id }});
                    history.go(0);
                    }
                    catch (e) {
                      window.alert('Could not delete series as it is used in gauge lines. Please delete the lines first.');
                      console.error(e);
                    }
                  } 
                }}
              >
                <Icon color="red" name="trash" />
              </Button>
            </div>
          ),
        })}
        defaultSortOrder={[{ type: 'ASC' }, { name: 'ASC' }]}
        defaultFilters={{ name: '' }}
        filters={[
          { key: 'name', label: 'NAME', type: 'text' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { };
          if (!isEmptyString(filterOptions.name))   queryFilters.name = filterOptions.name;
          return queryFilters;
        }}
      />
    </>
  )
};
export default SeriesList;