import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Header, Icon } from 'semantic-ui-react';
import { FONT_OPTIONS } from 'src/misc/dropdownOptions.js';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField.jsx';
import { isEmptyString } from 'src/misc/validators';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  scale_number: yup.number().required(),
  arc_rad: yup.number().required(),
  minor_line_inner_rad: yup.number().required(),
  minor_line_outer_rad: yup.number().required(),
  minor_strokewidth: yup.number().required(),
  major_line_inner_rad: yup.number().required(),
  major_line_outer_rad: yup.number().required(),
  major_strokewidth: yup.number().required(),
  major_text_rad: yup.number().required(),
  text_angle: yup.number().required(),
  strokecolor: yup.string().required().default('#000000'),
  fontname: yup.string().required(),
  fontsize: yup.number().required(),
  callout_line_inner_rad: yup.number().required(),
  callout_line_outer_rad: yup.number().required(),
  callout_text_rad: yup.number().required(),
  callout_strokewidth: yup.number().required(),
  callout_fontsize: yup.number().required(),
  callout_strokecolor: yup.string().required().default('#000000'),
  unit_text_x: yup.number().nullable(),
  unit_text_y: yup.number().nullable(),
  unit_text_angle: yup.number().required(),
  unit_text_fontname: yup.string().required(),
  unit_text_fontsize: yup.number().required(),
  label_text_fontname: yup.string().required(),
  label_text_fontsize: yup.number().required(),
  label_text_fillcolor: yup.string().required().default('#000000'),
  colorband_inner_rad: yup.number().required(),
  colorband_outer_rad: yup.number().required(),
  colorband_strokewidth: yup.number().required(),
  draw_arc: yup.bool().default(true),
  colorband_on_top: yup.bool().default(true),
  draw_deadband: yup.bool().default(true),
  align_text_absolute: yup.bool().default(false),
});

const DialTemplateScaleForm = ({ history, match }) => {
  const isUpdateForm = match.params.id_scale != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id_scale }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    if(isEmptyString(fields.unit_text_x)) fields.unit_text_x = null;
    if(isEmptyString(fields.unit_text_y)) fields.unit_text_y = null;
    fields.strokecolor = fields.strokecolor?.trim();
    fields.callout_strokecolor = fields.callout_strokecolor?.trim();
    fields.label_text_fillcolor = fields.label_text_fillcolor?.trim();
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id_scale, input: fields }})
      : await createMutation({ variables: { input: fields, id_dial_template: match.params.id } });
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.dial_template_scale ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Scale`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="scale_number" component={Form.Input} label="Scale number" type="number" />
              <Header as="h2">Arc</Header>
              <Form.Group widths={3}>
                <SemanticField name="arc_rad" component={Form.Input} label="Arc radius (mm)" type="number" />
                <SemanticField name="draw_arc" component={Form.Checkbox} label="Draw scale arc?" />
                <SemanticField name="draw_deadband" component={Form.Checkbox} label="Draw deadband?" />
              </Form.Group>
              <Header as="h2">Markings</Header>
              <Form.Group widths={3}>
                <SemanticField name="minor_line_inner_rad" component={Form.Input} label="Minor inner rad (mm)" type="number" />
                <SemanticField name="minor_line_outer_rad" component={Form.Input} label="Minor outer rad (mm)" type="number" />
                <SemanticField name="minor_strokewidth" component={Form.Input} label="Minor stroke (mm)" type="number" />
                <SemanticField name="major_line_inner_rad" component={Form.Input} label="Major inner rad (mm)" type="number" />
                <SemanticField name="major_line_outer_rad" component={Form.Input} label="Major outer rad (mm)" type="number" />
                <SemanticField name="major_strokewidth" component={Form.Input} label="Minor stroke (mm)" type="number" />
              </Form.Group>
              <Header as="h2">Text markings</Header>
              <Form.Group widths={3}>
                <SemanticField name="major_text_rad" component={Form.Input} label="Text radius (mm)" type="number" />
                <SemanticField name="align_text_absolute" component={Form.Checkbox} label="Align text to absolute angle instead of relative?" />
                <SemanticField name="text_angle" component={Form.Input} label="Text angle (deg)" type="number" />
                <SemanticField name="strokecolor" component={Form.Input} label="Text color" />
                <SemanticField name="fontname" component={Form.Dropdown} selection label="Font" options={FONT_OPTIONS} />
                <SemanticField name="fontsize" component={Form.Input} label="Font size (mm)" type="number" />
              </Form.Group>
              <Header as="h2">Callout markings</Header>
              <Form.Group widths={3}>
                <SemanticField name="callout_line_inner_rad" component={Form.Input} label="Callout inner rad (mm)" type="number" />
                <SemanticField name="callout_line_outer_rad" component={Form.Input} label="Callout outer rad (mm)" type="number" />
                <SemanticField name="callout_text_rad" component={Form.Input} label="Text radius (mm)" type="number" />
                <SemanticField name="callout_strokewidth" component={Form.Input} label="Stroke width (mm)" type="number" />
                <SemanticField name="callout_fontsize" component={Form.Input} label="Font size (mm)" type="number" />
                <SemanticField name="callout_strokecolor" component={Form.Input} label="Color" />
              </Form.Group>
              <Header as="h2">Unit text</Header>
              <Form.Group widths="equal">
                <SemanticField name="unit_text_x" component={Form.Input} label="Text center X (mm)" type="number" />
                <SemanticField name="unit_text_y" component={Form.Input} label="Text center Y (mm)" type="number" />
                <SemanticField name="unit_text_angle" component={Form.Input} label="Text angle (deg)" type="number" />
              </Form.Group>
              <Form.Group widths="equal">
                <SemanticField name="unit_text_fontname" component={Form.Dropdown} selection label="Font" options={FONT_OPTIONS} />
                <SemanticField name="unit_text_fontsize" component={Form.Input} label="Font size (mm)" type="number" />
              </Form.Group>
              <Header as="h2">Label text</Header>
              <Form.Group widths="equal">
                <SemanticField name="label_text_fontname" component={Form.Dropdown} selection label="Font" options={FONT_OPTIONS} />
                <SemanticField name="label_text_fontsize" component={Form.Input} label="Font size (mm)" type="number" />
                <SemanticField name="label_text_fillcolor" component={Form.Input} label="Text color" />
              </Form.Group>
              <Header as="h2">Colorbands</Header>
              <Form.Group widths="equal">
                <SemanticField name="colorband_inner_rad" component={Form.Input} selection label="Inner radius (mm)" type="number" />
                <SemanticField name="colorband_outer_rad" component={Form.Input} label="Outer radius (mm)" type="number" />
                <SemanticField name="colorband_strokewidth" component={Form.Input} label="Stroke width" type="number" />
                <SemanticField name="colorband_on_top" component={Form.Checkbox} label="Colorband on top?" />
              </Form.Group>

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default DialTemplateScaleForm;