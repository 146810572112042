import gql from 'graphql-tag.macro';

const f_box_scalar_details = gql`
  fragment box_scalar_details_fragment on Box {
    id
    is_outer_box
    length
    width
    height
    weight
    notes
  }
`;

export const m_create = gql`
  mutation box_create ($input: BoxCreateInput!) {
    box_create (input: $input) {
      id
    },
  }
`;

export const m_delete = gql`
  mutation box_delete ($id: ID!) {
    box_delete (id: $id) {
      id
    }
  }
`;

export const m_update = gql`
  mutation box_update ($id: ID!, $input: BoxUpdateInput!) {
    box_update (id: $id, input: $input) {
      id
    },
  }
`;

// Query used for prefilling box update form with current values
export const q_update = gql`
  query box_update ($id: ID!) {
    box (id: $id) {
      ...box_scalar_details_fragment
    },
  }
  ${f_box_scalar_details},
`;

// Query used for loading box list
export const q_list = gql`
  query boxes ($limit: Int, $offset: Int, $filter: BoxFilterInput, $sort: [BoxSortInput!]) {
    list: boxes (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      ...box_scalar_details_fragment
    }
  }
  ${f_box_scalar_details}
`;