import gql from 'graphql-tag.macro';

export const f_scalar_details = gql`
  fragment dial_template_scale_scalar_details_fragment on DialTemplateScale {
    id
    scale_number
    arc_rad
    minor_line_inner_rad
    minor_line_outer_rad
    major_line_inner_rad
    major_line_outer_rad
    callout_line_inner_rad
    callout_line_outer_rad
    major_text_rad
    callout_text_rad
    strokecolor
    callout_strokecolor
    minor_strokewidth
    major_strokewidth
    callout_strokewidth
    fontname
    fontsize
    align_text_absolute
    text_angle
    unit_text_x
    unit_text_y
    unit_text_angle
    unit_text_fontname
    unit_text_fontsize
    label_text_fontname
    label_text_fontsize
    label_text_fillcolor
    callout_fontsize
    draw_arc
    draw_deadband
    colorband_inner_rad
    colorband_outer_rad
    colorband_strokewidth
    colorband_on_top
  }
`;

export const m_delete = gql`
mutation dial_template_scale_delete ($id: ID!){
  dial_template_scale_delete (id: $id) {
    id
  }
}`;

export const m_create = gql`
mutation dial_template_scale_create ($id_dial_template: ID!, $input: DialTemplateScaleInput!){
  dial_template_scale_create (id_dial_template: $id_dial_template, input: $input) {
    id
  },
}`;

export const m_update = gql`
mutation dial_template_scale_update ($id: ID!, $input: DialTemplateScaleInput!){
  dial_template_scale_update (id: $id, input: $input) { 
    id
  },
}`;

export const q_update = gql`
query update_dial_template_scale ($id: ID!) {
  dial_template_scale (id: $id) {
    ...dial_template_scale_scalar_details_fragment
  },
  ${f_scalar_details},
}`;