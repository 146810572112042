import gql from 'graphql-tag.macro';

export const f_scalar_details = gql`
  fragment dial_template_image_scalar_details_fragment on DialTemplateImage {
    id
    cx
    cy
    w
    h
    angle
    data
    mime_type
  }
`;

export const m_delete = gql`
mutation dial_template_image_delete ($id: ID!){
  dial_template_image_delete (id: $id) {
    id
  }
}`;

export const m_create = gql`
mutation dial_template_image_create ($id_dial_template: ID!, $input: DialTemplateImageInput!){
  dial_template_image_create (id_dial_template: $id_dial_template, input: $input) {
    id
  },
}`;

export const m_update = gql`
mutation dial_template_image_update ($id: ID!, $input: DialTemplateImageInput!){
  dial_template_image_update (id: $id, input: $input) { 
    id
  },
}`;

export const q_update = gql`
query update_dial_template_image ($id: ID!) {
  dial_template_image (id: $id) {
    ...dial_template_image_scalar_details_fragment
  },
  ${f_scalar_details},
}`;