import dayjs from 'dayjs';
import numeral from 'numeral';
import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Button } from 'semantic-ui-react';
import CollectionList from 'src/misc/CollectionList';
import PageHeader from 'src/misc/PageHeader';
import { isEmptyString } from 'src/misc/validators.js';
import { UserContext } from 'src/routes/index.jsx';
import * as QUERIES from './queries.js';

const InvoiceList = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const user = useContext(UserContext);
  return (
    <>
      <PageHeader title="Invoices" 
        leftAction={user.isSales && <Button onClick={() => history.push(`${match.url}/create`)}>New</Button>}
        rightAction={user.isSales && <Button onClick={() => history.push(`${match.url}/einvoice`)}>Upload E-invoice</Button>} 
      />
      <CollectionList
        query={QUERIES.q_list}
        handleClickRow={(id) => history.push(`${match.url}/${id}`)}
        tableColumns={[
          { key: 'invoice_date', value: 'DATE', width: '12%', sortable: true },
          { key: 'id', value: 'ID', width: '12%', sortable: true },
          { key: 'customer_billing.name', value: 'CUSTOMER', width: '30%' },
          { key: 'id_sales_orders', value: 'ORDERS', width: '12%', whiteSpace: 'pre' },
          { key: 'status', value: 'STATUS', width: '12%' },
          { key: 'quantity', value: 'QTY', width: '10%' },
          { key: 'total_amount', value: 'AMOUNT', width: '12%', textAlign: 'right', hidden: !user.isSales && !user.isAccounts && !user.isOwner  },
        ]}
        mapDataRowToTable={invoice => ({
          ...invoice,
          key: invoice.id,
          'customer_billing.name': invoice.customer_billing.name,
          id_sales_orders: invoice.id_sales_orders.join('\n'),
          invoice_date: dayjs(invoice.invoice_date).format('DD MMM YY'),
          total_amount: `${invoice.currency.symbol} ${numeral(invoice.financials.total_amount).format('0,0.00')}`,
        })}
        defaultSortOrder={[{ 'date': 'DESC' }, { 'id': 'DESC' }]}
        defaultFilters={{
          id: '', idSalesOrder: '', customer: '', series: '', region: 'All', 
          status: user.isSales ? ['Sales', 'Production'] : ['Production'], dateFrom: '', dateTo: '',
        }}
        filters={[
          { key: 'id', label: 'ID', type: 'text' },
          { key: 'idSalesOrder', label: 'SALES ORDER', type: 'text' },
          { key: 'customer', label: 'CUSTOMER', type: 'text' },
          { key: 'series', label: 'SERIES', type: 'text' },
          { key: 'region', label: 'REGION', type: 'radio', options: ['Export', 'Domestic', 'All'] },
          { 
            key: 'status', label: 'STATUS', type: 'select', multiple: true, 
            options: user.isSales ? ['Sales', 'Production', 'Dispatched'] : ['Production', 'Dispatched'] 
          },
          { key: 'dateFrom', label: 'DATE FROM', type: 'date' },
          { key: 'dateTo', label: 'DATE TO', type: 'date' },
        ]}
        mapFiltersToQuery={(filterOptions) => {
          const queryFilters = { status: filterOptions.status };
          if (!isEmptyString(filterOptions.id))   queryFilters.id = filterOptions.id;
          if (!isEmptyString(filterOptions.customer))   queryFilters.customer = filterOptions.customer;
          if (!isEmptyString(filterOptions.series))   queryFilters.series = filterOptions.series;
          if (filterOptions.region === 'Domestic')  queryFilters.region = 'DOMESTIC';
          else if (filterOptions.region === 'Export') queryFilters.region = 'EXPORT';
          if (!isEmptyString(filterOptions.dateFrom)) queryFilters.date_from = filterOptions.dateFrom;
          if (!isEmptyString(filterOptions.dateTo)) queryFilters.date_to = filterOptions.dateTo;
          if (!isEmptyString(filterOptions.idSalesOrder)) queryFilters.id_sales_order = filterOptions.idSalesOrder;
          return queryFilters;
        }}
      />
    </>
  )
};
export default InvoiceList;