import gql from 'graphql-tag.macro';

export const m_create = gql`
  mutation invoice_create ($input: InvoiceCreateInput!) {
    invoice_create (input: $input) {
      id
    }
  },
`;

export const m_delete = gql`
  mutation invoice_delete ($id: ID!) {
    invoice_delete (id: $id) {
      id
    }
  }
`;

export const m_update = gql`
  mutation invoice_update ($id: ID!, $input: InvoiceUpdateInput!) {
    invoice_update (id: $id, input: $input) {
      id
    }
  },
`;

export const m_status_update = gql`
  mutation invoice_status_update ($id: ID!, $status: String!, $id_user: ID!) {
    invoice_status_update (id: $id, status: $status, id_user: $id_user) {
      id
    }
  },
`;

export const m_finalize = gql`
  mutation invoice_finalize ($old_id: ID!, $new_id: ID!, $id_user: ID!) {
    invoice_finalize (old_id: $old_id, new_id: $new_id, id_user: $id_user) {
      id
    }
  },
`;

export const m_gauge_line_delete = gql`
  mutation delete_gauge_line ($id: ID!) {
    delete_gauge_line (id: $id) {
      id
    }
  }
`;

export const m_inventory_sale_line_delete = gql`
  mutation delete_inventory_sale_line ($id: ID!) {
    delete_inventory_sale_line (id: $id) {
      id
    }
  }
`;

export const q_update = gql`
  query update_invoice ($id: ID!) {
    invoice (id: $id) {
      id
      id_customer_billing
      id_address_billing
      id_customer_shipping
      id_address_shipping
      freight_terms
      insurance_terms
      payment_terms
      bank_terms
      trade_terms
      freight_carrier
      freight_mode
      airwaybill_number
      invoice_date
      discount_percent
      discount
      advance
      credit_days
      freight_charge
      handling_charge
      pf_charge
      wire_charge
      additional_charges {
        description
        amount
        sac_code
        is_taxable
      }
      id_currency
      exchange_rate
      cgst
      sgst
      igst
      tcs
      einv_suptyp
      einv_ewaybill_num
      einv_regrev
      einv_igstonintra
      notes
    }
  },
`;

export const m_invoice_add_sales_order_line = gql`
  mutation invoice_add_sales_order_line ($id_invoice: ID!, $id_sales_order: ID!, $line: Int) {
    invoice_add_sales_order_line (id_invoice: $id_invoice, id_sales_order: $id_sales_order, line: $line)
  }
`;

export const m_invoice_remove_sales_order_line = gql`
  mutation invoice_remove_sales_order_line ($id_line: ID!)  {
    invoice_remove_sales_order_line (id_line: $id_line)
  }
`;

export const q_form = gql`
  query invoice_form {
    customers (limit: 10000, sort: { name: ASC }) {
      id
      name
      addresses {
        id
        street
        city
        district
        state
        country
        zipcode
      }
    }

    currencies {
      id
      name
    }
  }
`;

export const q_detail = gql`
  query invoice ($id: ID!) {
    invoice (id: $id) {
      id
      invoice_date
      freight_carrier
      freight_mode
      freight_terms
      insurance_terms
      payment_terms
      bank_terms
      trade_terms
      airwaybill_number
      credit_days
      notes
      status
      id_finalized_by
      currency {
        id
        name
        symbol
      }
      exchange_rate
      customer_billing {
        id
        name
      }
      address_billing {
        id
        street
        city
        state
        district
        country
        zipcode
      }
      customer_shipping {
        id
        name
      }
      address_shipping {
        id
        street
        city
        state
        district
        country
        zipcode
      }
      quantity
      financials {
        basic_amount
        taxable_amount
        discount_amount
        pf_charge_amount
        total_amount
        all_charges {
          description
          amount
          is_taxable
        }
        cgst_amount
        sgst_amount
        igst_amount
        tcs_amount
      }
      advance
      tcs
      cgst
      sgst
      igst
      einv_suptyp
      einv_irn
      einv_ewaybill_num
      einv_irn_date
      inventory_sale_lines {
        id
        sales_order {
          id
          id_purchase_order
          status
          order_date
        }
        line
        end_customer_po
        article_number
        quantity
        price
        amount
        customer_code
        label_notes
        notes
        inventory {
          id
          model_code
        }
        production_status {
          qty_assigned_box
          qty_packed
        }
      }
      gauge_lines {
        id
        sales_order {
          id
          id_purchase_order
          status
          order_date
        }
        line
        model_code
        production_status {
          qty_assembled
          qty_angle_checked
          qty_calibrated
          qty_dial_crosschecked
          qty_switch_crosschecked
          qty_finished
          qty_checked
          qty_assigned_box
          qty_packed
        }
        quantity
        price
        amount
      }
    }
  },
`;

export const q_list = gql`
  query invoices ($limit: Int!, $offset: Int!, $filter: InvoiceFilterInput, $sort: [InvoiceSortInput!]) {
    list: invoices (limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      id
      einv_irn
      customer_billing {
        id
        name
      }
      id_sales_orders
      status
      currency {
        id
        symbol
      }
      invoice_date
      quantity
      financials {
        total_amount
      }
    }
  }
`;
