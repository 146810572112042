import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import ImageUploadField from 'src/misc/ImageUploadField';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  cx: yup.number().required(),
  cy: yup.number().required(),
  w: yup.number().required(),
  h: yup.number().required(),
  angle: yup.number().default(0).min(-180).max(180).required(),
  image: yup.object().required(),
});

const DialTemplateImageForm = ({ history, match }) => {
  const isUpdateForm = match.params.id_image != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id_image }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async ({ image, ...rawFields }, { setFieldError }) => {
    if (!isUpdateForm && image.data == null) {
      setFieldError('image', 'Image file not selected');
      return;
    }
    const fields = validationSchema.cast(rawFields);
    delete fields.id;
    fields.data = image.data;
    fields.mime_type = image.mime_type;
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id_image, input: fields }})
      : await createMutation({ variables: { input: fields, id_dial_template: match.params.id } });
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  
  const initialValues = stripTypename(updateQuery?.data?.dial_template_image ?? validationSchema.default());
  const { data, mime_type } = initialValues;
  initialValues.image = { data, mime_type };

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Image`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              <SemanticField name="cx" component={Form.Input} label="Center X (mm)" type="number" />
              <SemanticField name="cy" component={Form.Input} label="Center Y (mm)" type="number" />
              <SemanticField name="w" component={Form.Input} label="Width (mm)" type="number" />
              <SemanticField name="h" component={Form.Input} label="Height (mm)" type="number" />
              <SemanticField name="angle" component={Form.Input} label="Angle (deg)" type="number" />
              <ImageUploadField name="image" label="Image" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default DialTemplateImageForm;