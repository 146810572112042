import { useMutation, useQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import { stripTypename } from 'src/misc/helpers';
import PageHeader from 'src/misc/PageHeader';
import SemanticField from 'src/misc/SemanticField';
import { isEmptyString } from 'src/misc/validators';
import * as yup from 'yup';
import * as QUERIES from './queries';

const validationSchema = yup.object().shape({
  id: yup.string().required(),
  length: yup.number().required(),
  height: yup.number().required(),
  width: yup.number().required(),
  weight: yup.number().required(),
  notes: yup.string().nullable(),
  is_outer_box: yup.bool().default(false),
});

const BoxForm = ({ history, match }) => {
  const isUpdateForm = match.params.id != null;
  const [updateMutation] = useMutation(QUERIES.m_update);
  const [createMutation] = useMutation(QUERIES.m_create);
  const updateQuery = useQuery(QUERIES.q_update, { variables: { id: match.params.id }, skip: !isUpdateForm });
  
  if (updateQuery?.loading) return null;
  if (updateQuery.error)  return `Error!: ${JSON.stringify(updateQuery.error, null, 2)}`;

  const handleSubmit = async (rawFields) => {
    const fields = validationSchema.cast(rawFields);
    if(isUpdateForm) delete fields.id;
    if (isEmptyString(fields.notes))  fields.notes = null;
    isUpdateForm
      ? await updateMutation({ variables: { id: match.params.id , input: fields }})
      : await createMutation({ variables: { input: fields } });
    history.go(-1);
  }

  const handleReset = () => {
    history.go(-1);
  }
  const initialValues = stripTypename(updateQuery?.data?.box ?? validationSchema.default());

  return (
    <>
      <PageHeader title={`${isUpdateForm ? 'Update' : 'Create'} Box`} />
      <Container style={{ paddingTop: '48px' }}>
        <Formik 
          initialValues={initialValues} enableReinitialize 
          onSubmit={handleSubmit} onReset={handleReset}
          validationSchema={validationSchema}
        >
          {formikProps => (
            <Form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset}>
              {!isUpdateForm && <SemanticField fast name="id" component={Form.Input} label="ID" type="text" />}
              <SemanticField fast name="length" component={Form.Input} label="Length (mm)" type="number" />
              <SemanticField fast name="height" component={Form.Input} label="Height (mm)" type="number" />
              <SemanticField fast name="width" component={Form.Input} label="Width (mm)" type="number" />
              <SemanticField fast name="weight" component={Form.Input} label="Weight (kg)" type="number" />
              <SemanticField fast name="is_outer_box" component={Form.Checkbox} label="Outer box?" />
              <SemanticField fast name="notes" component={Form.TextArea} label="Notes" />

              <Button color="red" icon labelPosition="left" type="reset">
                <Icon name="cancel" />Discard
              </Button>
              <Button icon labelPosition="left" type="submit" disabled={formikProps.isSubmitting || !formikProps.dirty}>
                <Icon name="save" />Save
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  )
}

export default BoxForm;